import React, { useState, useEffect } from 'react';

import './NavBarItem.css';

import CharactersButton from './CharactersButton.js';
import RulesButton from './RulesButton.js';
import SpellsButton from './SpellsButton.js';
import RollDiceButton from './RollDiceButton.js';
import SettingsButton from './SettingsButton.js';

// ----------------------------------------------------------------------------------
//                         Description
//
//     What does NavBarItem do?
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function NavBarItem(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------
    
    // -------- local state variables --------

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {    

        }

        DoSomething();

    }, [,]); //useEffect

    const characters = ['Yarg Smashskull', 'Kendra', 'sample', 'Yrg' ];



    function NavBarDropdown(navName) {
        if (navName === 'Characters') {
            return (
                <CharactersButton {...props} />
            )

        } else if (navName === 'Rules') {
            return (
                 <RulesButton {...props} />
            )

        } else if (navName === 'Spells') {
            return (
                 <SpellsButton {...props} />
            )

        } else if (navName === 'Roll Dice') {
            return (
                 <RollDiceButton {...props} />
            )

        } else if (navName === 'Settings') {
            return (
                 <SettingsButton {...props} />
            )

        } else {
            return (
                <div className="navbaritem">
                    <button {...props } className="nav-menu-button" onClick={()=>handleNotReady()}>{navName}</button>
                </div>
            )
        }

        }
    
    // ---------- return html (jsx) ----------

    return (
        <>
            { NavBarDropdown(props.children) }
        </>
    )

    };  // end of function

    const handleNotReady = () => {
        alert('Future feature');
      }

    export default NavBarItem