import React, { useState, useEffect } from 'react';

import './Spell.css'
import './SpellMobile.css'

import load_spell from './helpers/load_spell.js'


// ----------------------------------------------------------------------------------
//                         Description
//
//     What does this do?
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function Spell(props) {

    // ------------ parameters ---------------
       // props.spellname has the name of the spell to display
       const spelldata_test = 
        {
            "Class": "Cleric",
            "Name": "Bless",
            "AOE": "5\"x5\"",
            "Casting": "1 rd",
            "Comps": "VSM",
            "Description": "Upon uttering the Bless spell, the caster raises the morale of friendly creatures by +1. Furthermore, it raises their \"to hit\" dice rolls by +1. A blessing, however, will affect only those not already engaged in melee combat. This spell can be reversed by the cleric to a curse upon enemies which lowers morale and \"to hit\" by -1. The caster determines at what range (up to 6\") he or she will cast the spell, and it then affects all creatures in on area 5\" square centred on the point the spell was cast upon. In addition to the verbal and somatic gesture components, the Bless requires holy water, while the Curse requires the sprinkling of specially polluted water. ",
            "Duration": "6 rds",
            "Effect": "+1 to hit",
            "Lvl": "1",
            "Range": "6\"",
            "Rev?": "Yes",
            "Save": "No",
            "School": "Conjuration"
        }

    // ------ inherited state variables ------
    const [ spellname, setSpellName ] = useState(props.spellname);
    const spelldata = props.spelldata;
    const spellID = props.ID || "";   // this will be pre-pended to all ID's
    const UItype = props.UItype || "";

    // -------- local state variables --------
    
    var classAppend = UItype ? '-' + UItype : "";

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {

            // translate abbreviations into full names

            const spell_lowered = spellname.toLowerCase();
            fetch('config/spell-abbrevs.json')
                .then ((response) => response.json())
                .then ((data) => {
                    
                    if (spell_lowered in data ) {
                        console.log(data[spell_lowered]);
                        setSpellName(data[spell_lowered]);
                        load_spell(data[spell_lowered]);
                    } else {
                        console.log('No spell abbrev found');
                    }
                    
                }
                
                )
            console.log(spellname);
        }

        DoSomething();

    }, []); //useEffect

    const handleClose = (e) => {
        console.log('HANDLE CLOSE');
        e.target.parentElement.parentElement.style.display = 'none';
    }
    // ---------- return html (jsx) ----------

    return (
        <>
        <div className={"spell-details"+classAppend} id={ spellID + "spell-details" } >
            <div className={"spell-detail-title"+classAppend}>{ spelldata.Name }</div>
            <div className={"spell-detail-casting"+classAppend+ " spell-info"} >Casting Time: <span className="spell-info-value">{spelldata.Casting}</span></div>
            <div className={"spell-detail-duration"+classAppend+ " spell-info" } >Duration: <span className="spell-info-value">{spelldata.Duration}</span></div>
            <div className={"spell-detail-range"+classAppend+ " spell-info" } >Range: <span className="spell-info-value">{spelldata.Range}</span></div>
            <div className={"spell-detail-save"+classAppend+ " spell-info" } >Save: <span className="spell-info-value">{spelldata.Save}</span></div>
            <div className={"spell-detail-aoe"+classAppend+ " spell-info" } >Area of Effect: <span className={"spell-info-value"+classAppend + " spell-value-aoe"+classAppend} >{spelldata.AOE}</span></div>
            <div className={"spell-detail-summary"+classAppend+ " spell-info" }>
                <button className={"close-spell-detail"+classAppend} onClick={handleClose}>X</button>
                <div className='spell-summary-lg' id='spell-summary-class'>{spelldata.Class}</div>
                <div className='spell-summary-lg' id='spell-summary-lvl'>{spelldata.Lvl}</div>
                <div className='spell-summary-sm' id='spell-summary-school'>{spelldata.School}</div>
                <div className='spell-summary-sm' id='spell-summary-comps'>{spelldata.Comps}</div>
                <div className='spell-summary-sm' id='spell-summary-rev'>{( spelldata['Rev?'] ) ? ( (spelldata['Rev?'] === "No") ? "" : "Reversible" ) : ( "" ) }</div>
            </div>
            <div className={"spell-detail-effect"+classAppend+ " spell-info-value"}>
                <pre className={"spell-detail-effect"+classAppend+ " spell-info-value"} dangerouslySetInnerHTML={{__html: spelldata.Effect }} /></div>
            <div className={"spell-detail-desc"+classAppend} >
                <pre className={"spell-detail-desc"+classAppend} dangerouslySetInnerHTML={{__html: spelldata.Description }} /></div>
        </div>
        </>
    )

    };  // end of function

    export default Spell