import React, { useState, useEffect } from 'react';

import './RollDie.css'

// ----------------------------------------------------------------------------------
//                         Description
//
//     RollDie is a React component for rolling multiple dice of a single type
/*          Inputs:
            NrSides
            Rolls
            setParentNrDice  ( function to update the number of dice in parent )

            State:
            NrDice

            div = flex, flex-direction='column', align-content: center
            Sections:
            1. Image
            2. Up button => increments counter
            3. Counter
            4. Down button => decrements counter (0 min)
            5. Rolls (show each roll in order)

            6. Results (hide if Counter = 
            6A. Total
            6B. Min
            6C. Max
            6D. Best 3
*/
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function RollDie(props) {

    // ------------ parameters ---------------
    const nullFunction = () => {}

    // ------ inherited state variables ------
    const NrSides = props.NrSides || 8 ;
    const rolls = props.Rolls;
    const setParentDice = props.Set || nullFunction;
    const NrDice = props.NrDice;

    // -------- local state variables --------
    // const [ NrDice, setNrDice ] = useState(rolls.length);
    const [ dieImage, setDieImage ] = useState ( require( '../../img/die-' + NrSides.toString() + '-numbered.png' ) );

    const [rollTotal, setRollTotal ] = useState('3.14');
    const [ rollMax, setRollMax ] = useState('00');
    const [ rollMin, setRollMin ] = useState('99');
    const [ rollBest, setRollBest ] = useState('3');
    const [ totalRollText, setTotalRollText ] = useState('Total');
    const [ bestRollText, setBestRollText ] = useState('Best 3');

    // ------------- useEffect ---------------

    useEffect( () => {

        function updateStats () {    
            
            let L = rolls.length;
            let sum = 0;
            let ordered_rolls = [...rolls]
            ordered_rolls.sort(function (a, b) {  return a - b;  });

            for (let i in rolls ) { 
                let roll = rolls[i]
                sum += parseInt(roll)
            }
            setRollTotal(sum);

            let sum2 = 0
            if ( L > 2 ) {
                for ( let i=1; i<4; i++ ){
                sum2 += ordered_rolls[L-i]
                }
            }
            
            if ( parseInt(NrSides) === 10 & NrDice > 1 ) {
                let roll100 = 50;
                let digit1 = parseInt(rolls[0]);
                let digit2 = parseInt(rolls[1]);
                if ( digit1 > 9 ) { digit1 -= 10; }
                if ( digit2 > 9 ) { digit2 -= 10; }
                roll100 = digit1*10 + digit2;
                if ( roll100 === 0 ) { roll100 = 100; }
                console.log(roll100)
                // setRollBest( roll100.toString() )   <= swap these with the total
                // setBestRollText('100 Roll');
                setRollTotal( roll100.toString() );
                setTotalRollText('1-100');
                setRollBest( sum );
                setBestRollText('Total');
            }

            else { setRollBest(sum2); }

            setRollMin(ordered_rolls[0]);
            setRollMax(ordered_rolls[L-1]);
            
        }

        updateStats();



    }, [rolls, NrDice, NrSides ]); //useEffect

    // ---------- event handlers -------------

    function handleUp(e) {
        setParentDice(NrDice+1);
        //setNrDice(NrDice+1);
    }

    function handleDown(e) {
        if ( NrDice > 0 ) { setParentDice(NrDice-1); 
            // setNrDice( NrDice-1 );
         }
    }

    function handleQty(e) {
        console.log(e.target)
    }
    
    const displayRolls = (RLS) => {
        let retString = ''
        for ( let R in RLS ) {
            retString += RLS[R];
            retString += ','
        }
        retString = retString.slice(0, -1);

        return retString
    }
    // ---------- return html (jsx) ----------

    return (
        <div className='rolldie' id={'rolldie-' + NrSides.toString() }>
            <img className='rolldie-img' src={dieImage} alt='die' />
            
            <button className='rolldie-updown' onClick={ handleUp }>▲</button>
            <input className='rolldie-qty' onChange={ handleQty } type='number' value={NrDice} ></input>
            <button className='rolldie-updown' onClick={ handleDown }>▼</button>

            <div className='rolldie-rolls'>{ displayRolls(rolls) }</div>

            <div className='rolldie-stats'>
                <div className='rolldie-stat-text rolldie-Total'>{ totalRollText }</div>
                <div className='rolldie-stat-number rolldie-Total'>{ rollTotal }</div>
                <div className='rolldie-stat-text'>Max</div>
                <div className='rolldie-stat-number'>{ rollMax }</div>
                <div className='rolldie-stat-text'>Min</div>
                <div className='rolldie-stat-number'>{ rollMin }</div>
                <div className='rolldie-stat-text rolldie-best'>{ bestRollText }</div>
                <div className='rolldie-stat-number rolldie-best'>{ rollBest }</div>
            </div>
        </div>
    )

    };  // end of function

    export default RollDie