import React, { useState, useEffect } from 'react';

import NavBarItem from './NavBarItem.js'
import NavBarButton from './CharactersButton.js';

// ----------------------------------------------------------------------------------
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function Index(props) {

    // ---------- return html (jsx) ----------

    return (
        <NavBarItem {...props} />
    )

    };  // end of function

    export default Index