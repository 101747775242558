import React, { useState, useEffect } from 'react';

import CharsheetLong from '../../components/CharsheetLong'
import RollDice from '../../components/RollDice'

import FlexiGrid from '../../components/common/Flexigrid/Flexigrid.js';

import sample_data from '../../components/Charsheet0/helpers/sample_data.js'

import Charsheet01 from '../Charsheet01'

import './Charsheet05.css'

// ----------------------------------------------------------------------------------
//                         Description
//
//     Charsheet05 is a page with 5 characters wide
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function Charsheet05(props) {

    console.log('CHAR05 props', props)

    // ------------ parameters ---------------

    let startData = { ...sample_data } // <= needs flattening !!

    let flatData = startData.chardata.chars
    for ( var key in flatData ) {
        startData[key] = flatData[key];
    }

    flatData = startData.chardata.stats;
    for ( key in flatData ) {
        startData[key] = flatData[key];
    }

    flatData = startData.chardata.weapons; 
    for ( key in flatData ) {
        startData[key] = flatData[key];
    }

    flatData = startData.chardata.armor;
    for ( key in flatData ) {
        startData[key] = flatData[key];
    }

    flatData = startData.chardata.saves;
    for ( key in flatData ) {
        startData[key] = flatData[key];
    }

    flatData = startData.chardata.spells;
    for ( key in flatData ) {
        startData[key] = flatData[key];
    }

    flatData = startData.chardata.magic;
    for ( key in flatData ) {
        startData[key] = flatData[key];
    }

    flatData = startData.chardata.items;
    for ( key in flatData ) {;
        startData[key] = flatData[key];
    }

    flatData = startData.chardata.potions;
    for ( key in flatData ) {;
        startData[key] = flatData[key];
    }

    flatData = startData.chardata.abilities;
    for ( key in flatData ) {;
        startData[key] = flatData[key];
    }


    // ------ inherited state variables ------
    
    // props.state = charState = [currentChar, setChar]
    const [currentChar, setChar] = props.state;
    
    // -------- local state variables --------
    const [ char0data, setChar0Data ] = useState( startData );
    const [ char1data, setChar1Data ] = useState( startData );
    const [ char2data, setChar2Data ] = useState( startData );
    const [ char3data, setChar3Data ] = useState( startData );
    const [ char4data, setChar4Data ] = useState( startData );
    const [ char5data, setChar5Data ] = useState( startData );
    const [ char6data, setChar6Data ] = useState( startData );
    const [ char7data, setChar7Data ] = useState( startData );
    const [ char8data, setChar8Data ] = useState( startData );
    const [ char9data, setChar9Data ] = useState( startData );
    const [ char10data, setChar10Data ] = useState( startData );
    const [ char11data, setChar11Data ] = useState( startData );
    const [ char12data, setChar12Data ] = useState( startData );
    const [ char13data, setChar13Data ] = useState( startData );
    const [ char14data, setChar14Data ] = useState( startData );
    const [ char15data, setChar15Data ] = useState( startData );
    const [ char16data, setChar16Data ] = useState( startData );
    const [ char17data, setChar17Data ] = useState( startData );
    const [ char18data, setChar18Data ] = useState( startData );
    const [ char19data, setChar19Data ] = useState( startData );
    const [ char20data, setChar20Data ] = useState( startData );

    // spellChecks for char0
    
    let spellCheckArray = [ [0], [1], [2], [3], [4], [5], [6], [7], [8], [9], [10] ]
    let spellChecks11 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks11);
    let spellChecks12 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks12);
    let spellChecks13 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks13);
    let spellChecks14 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks14);
    let spellChecks15 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks15);
    let spellChecks16 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks16);
    let spellChecks17 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks17);
    let spellChecks18 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks18);
    let spellChecks19 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks19);
    let spellChecks20 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks20);
    let spellChecks21 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks21);
    let spellChecks22 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks22);
    let spellChecks23 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks23);
    let spellChecks24 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks24);
    let spellChecks25 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks25);
    let spellChecks26 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks26);
    let spellChecks27 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks27);
    let spellChecks28 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks28);
    let spellChecks29 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks29);
    let spellChecks30 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks30);
    let spellChecks31 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks31);
    let spellChecks32 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks32);
    let spellChecks33 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks33);
    let spellChecks34 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks34);
    let spellChecks35 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks35);
    let spellChecks36 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks36);
    let spellChecks37 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks37);
    let spellChecks38 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks38);
    let spellChecks39 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks39);
    let spellChecks40 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks40);
    let spellChecks41 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks41);
    let spellChecks42 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks42);
    let spellChecks43 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks43);
    let spellChecks44 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks44);
    let spellChecks45 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks45);
    let spellChecks46 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks46);
    let spellChecks47 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks47);
    let spellChecks48 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks48);
    let spellChecks49 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks49);
    let spellChecks50 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks50);
    let spellChecks51 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks51);
    let spellChecks52 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks52);
    let spellChecks53 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks53);
    let spellChecks54 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks54);
    let spellChecks55 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks55);
    let spellChecks56 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks56);
    let spellChecks57 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks57);
    let spellChecks58 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks58);
    let spellChecks59 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks59);
    let spellChecks60 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks60);
    let spellChecks61 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks61);
    let spellChecks62 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks62);
    let spellChecks63 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks63);
    let spellChecks64 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks64);
    let spellChecks65 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks65);
    let spellChecks66 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks66);
    let spellChecks67 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks67);
    let spellChecks68 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks68);
    let spellChecks69 = useState(['69   ','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks69);
    let spellChecks70 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks70);

    // ------------- useEffect ---------------

    useEffect( () => {

    },); //useEffect

    // ---------- return html (jsx) ----------

    const rowLabels = () => {

        const rowNames = ['Name','Class','Race','Align','STR','INT','WIS','DEX','CON','CHA','HP','Weap','Armor','Saves','Follow','Spells','Magic','Items', 'Pots','Racial', 'Other', 'Gold','XP'];
        const rowEnds =  [        4,      6,     8,      10,   12,   14,   16,   18,   20,   22,   26,   36,     46,     52,      56,      74,     79,      89,    95,       101,      107,  109,  113];

        let cells = {}
        let data = {}

        for ( var row in rowNames ) {
            let name = rowNames[row];
            let rowStart = 1;
            if (row>0) { rowStart = rowEnds[row-1]};
            let rowEnd = rowEnds[row]
            cells['txt'+name] = [1,2,rowStart,rowEnd]
            data['txt'+name] = name
        }
        return {cells: cells, data: data };
    }


    // ---------  Return JSX ---------------------------------------------------------------------

    return (
        <>
        <div id = 'five-wide' style={{marginTop: '5px', width: 'fit-content', position: 'relative' }}>

        <Charsheet01 state={ props.state } data={ char0data } setdata={ setChar0Data } charID='char0' spellCheckArray={spellCheckArray} />

        <RollDice class="full" />

        <div id = 'char-form-05' className='no-print' style={{width: 'fit-content', position: 'relative', flexDirection: 'row', display: 'none' }}>
            <FlexiGrid
                rowSize = '9px'
                colSize = '40px'
                id='labels'
                classRoot= 'lab'
                cells = { rowLabels().cells }
                data = { rowLabels().data }
                boxes={{ boxName: [1,2,1,4], boxA: [ 1,2,4,10 ], boxB:[1,2,10,22], boxC: [1,2,22,26], boxD: [1,2,26,36], boxE: [1,2,36,46],
                         boxF: [1,2,46,52], boxG:[1,2,52,56], boxSpells: [1,2,56,74],
                         boxMagic: [1,2,74,79], boxItems:[1,2,79,89], boxPots: [1,2,89,95], 
                         boxRacial: [1,2,95,101], boxOther: [1,2,101,107], boxGold: [1,2,107,109], boxXP: [1,2,109,113]
                        }}

                       
            />

            <CharsheetLong state={props.state} data={char1data} setdata={setChar1Data} charID='char1' />
            <CharsheetLong state={props.state} data={char2data} setdata={setChar2Data} charID='char2' />
            <CharsheetLong state={props.state} data={char3data} setdata={setChar3Data} charID='char3' />
            <CharsheetLong state={props.state} data={char4data} setdata={setChar4Data} charID='char4' />
            <CharsheetLong state={props.state} data={char5data} setdata={setChar5Data} charID='char5' />
            <CharsheetLong state={props.state} data={char6data} setdata={setChar6Data} charID='char6' />
            <CharsheetLong state={props.state} data={char7data} setdata={setChar7Data} charID='char7' />
            <CharsheetLong state={props.state} data={char8data} setdata={setChar8Data} charID='char8' />
            <CharsheetLong state={props.state} data={char9data} setdata={setChar9Data} charID='char9' />
            <CharsheetLong state={props.state} data={char10data} setdata={setChar10Data} charID='char10' />
            <CharsheetLong state={props.state} data={char11data} setdata={setChar11Data} charID='char11' />
            <CharsheetLong state={props.state} data={char12data} setdata={setChar12Data} charID='char12' />
            <CharsheetLong state={props.state} data={char13data} setdata={setChar13Data} charID='char13' />
            <CharsheetLong state={props.state} data={char14data} setdata={setChar14Data} charID='char14' />
            <CharsheetLong state={props.state} data={char15data} setdata={setChar15Data} charID='char15' />
            <CharsheetLong state={props.state} data={char16data} setdata={setChar16Data} charID='char16' />
            <CharsheetLong state={props.state} data={char17data} setdata={setChar17Data} charID='char17' />
            <CharsheetLong state={props.state} data={char18data} setdata={setChar18Data} charID='char18' />
            <CharsheetLong state={props.state} data={char19data} setdata={setChar19Data} charID='char19' />
            <CharsheetLong state={props.state} data={char20data} setdata={setChar20Data} charID='char20' />

            
        </div>
        </div>
        </>
    )

    };  // end of function

    export default Charsheet05