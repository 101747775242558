// sanitizeInput function
//
//    uses DOMPurify to clean inputs prior to use
//    also limits input length to a fixed number
//

import DOMPurify from 'dompurify';


// global app constant. MOVE TO ENVIROMENT VARIABLE
const MAXSTRINGLENGTH = 150

const sanitizeInput = (input) => {
  if (input.length > MAXSTRINGLENGTH ) {
    alert('Max field length is 100 characters. Concatenating');
    input = input.slice(0,MAXSTRINGLENGTH+1);
  }
  return DOMPurify.sanitize(input);
};

export default sanitizeInput