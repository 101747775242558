var sample_data = 
{
    "campaign": "Greyhawk20",
    "name": "Welcome to ADM",
    "chardata": {
     "abilities": {
      "myAbi": "blacksmithing",
      "myRabi": "\nInfravision 60'\n+1 to hit orcs,gobs\n+4 vs magic,poison\n-4 AC vs giants, trolls"
     },
     "armor": {
      "myAb1": "5",
      "myAb2": "2",
      "myAb3": "2",
      "myAb4": "2",
      "myAb5": "",
      "myArm1": "Chainmail",
      "myArm2": "Shield +1",
      "myArm3": "Ring of Protection +2",
      "myArm4": "Dexterity Bonus",
      "myArm5": "",
      "myBaseAC": "11"
     },
     "chars": {
      "myAC": "11",
      "myAlign": "NG",
      "myClass": "Fighter/Mage",
      "myDeity": "Ehlonna",
      "myGold": "1250",
      "myHei": "5'11",
      "myHPbase": "78",
      "myHPcurr": "63",
      "myHPnote": "poisoned by toad",
      "myLevel": "7/7",
      "myMov": "9\"",
      "myNatt": "3/2",
      "myRace": "Bipolar",
      "myRear": "6",
      "mySide": "5",
      "myToHit": "+6",
      "myWei": "165",
      "myXP": "144,700",
      "myXPbonus": "+10%",
      "myXPnext": "200k"
     },
     "items":{"myOth1":"Locks +15 (37) | Traps +5 (35)","myOth2":"Silent +15 (33) | Hide +20 (25)","myOth3":"Pickpok +10 (45) | Hear +5 (15)","myOth4":"Climb Walls -15 (88) ","myOth5":"Lang -10 (20) | Backstab x2"},
     "magic": {
      "myMag1": "Ring of Protection +2",
      "myMag2": "Wand of Magic Missiles (42)",
      "myMag3": "Diadem of Pow",
      "myMag4": "",
      "myMag5": ""
     },
     "potions": {
      "myPot1": "Healing",
      "myPot2": "Extra Healing",
      "myPot3": "Stone Giant Strength",
      "myPot4": "Wizardry",
      "myPot5": "Sweet Water",
      "myPot6": "",
      "myQpo1": "2",
      "myQpo2": "1",
      "myQpo3": "1",
      "myQpo4": "1",
      "myQpo5": "1",
      "myQpo6": "",
     },
     "followers": {
        "myFol1": "",
        "myFol2": "",
     },
     "saves": {
      "mySav1": "14",
      "mySav2": "12",
      "mySav3": "9",
      "mySav4": "10",
      "mySav5": "11",
      "mySbon1": "+2",
      "mySbon2": "+2",
      "mySbon3": "+2",
      "mySbon4": "+2",
      "mySbon5": "+2",
      "mySbon6": "+2 vs cold"
     },
     "spells": {
      "myNsp1": "5",
      "myNsp2": "5",
      "myNsp3": "4",
      "myNsp4": "1",
      "myNsp5": "0",
      "mySp11": "Magic Missile",
      "mySp12": "Light",
      "mySp13": "Sleep",
      "mySp14": "Comprehend Lang",
      "mySp15": "",
      "mySp16": "",
      "mySp17": "",
      "mySp18": "",
      "mySp21": "Web",
      "mySp22": "Strength",
      "mySp23": "Knock",
      "mySp24": "",
      "mySp25": "",
      "mySp26": "",
      "mySp27": "",
      "mySp28": "",
      "mySp31": "Fireball",
      "mySp32": "Haste",
      "mySp33": "Lightning Bolt",
      "mySp34": "",
      "mySp35": "",
      "mySp36": "",
      "mySp37": "",
      "mySp38": "",
      "mySp41": "Dimension Door",
      "mySp42": "Wall of Ice",
      "mySp43": "",
      "mySp44": "",
      "mySp45": "",
      "mySp46": "",
      "mySp47": "",
      "mySp48": "",
      "mySp51": "Cone of Cold",
      "mySp52": "",
      "mySp53": "",
      "mySp54": "",
      "myTsp1": "5",
      "myTsp2": "5",
      "myTsp3": "4",
      "myTsp4": "1",
      "myTsp5": "0"
     },
     "stats": {
      "myCha": "15",
      "myChaBon": "",
      "myCon": "15",
      "myConBon": "+1",
      "myDex": "16",
      "myDexBon": "+1/-2",
      "myInt": "17",
      "myIntBon": "",
      "myStr": "18/01",
      "myStrBon": "+1/+3",
      "myWis": "11",
      "myWisBon": "+3"
     },
     "weapons": {
      "myWdb1": "+2",
      "myWdb2": "+3",
      "myWdb3": "*",
      "myWdb4": "*",
      "myWdb5": "",
      "myWdmg1": "1-8 / 1-12",
      "myWdmg2": "1-4 / 1-3",
      "myWdmg3": "7d6",
      "myWdmg4": "7d4+7",
      "myWdmg5": "",
      "myWeap1": "Longsword +2 Frostblade",
      "myWeap2": "Dagger +1",
      "myWeap3": "Fireball",
      "myWeap4": "Magic Missile",
      "myWeap5": "",
      "myWth1": "+3",
      "myWth2": "+2",
      "myWth3": "*",
      "myWth4": "*",
      "myWth5": ""
     }
    },
    "version": "0.3"
   }

   export default sample_data