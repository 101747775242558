import { React, useState } from 'react';

import NavBar from '../components/NavBar'
import Charsheet05 from './Charsheet05'
import Help from '../components/Help'
import Image01 from '../pdf/CombatActions.jpg'
import RulesWindow from '../components/RulesWindow'
import AllSpells from '../components/AllSpells'

import './Home.css'

// ----------------------------------------------------------------------------------
//                         Description
//
//     What does Charsheet01 do?
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------
  

function Home(props) {

  // ------------ parameters ---------------
  
  // ------ inherited state variables ------
  console.log('HOME props: ', props)

  // ---------- local state variables ----------
  const [rulesWindowSrc, setRulesWindowSrc ] = useState( { title: 'Combat Actions', source: Image01 } )
  

  // ---------- handler functions ----------


  // ---------- return html (jsx) ----------
  return (
  <>
    <NavBar {...props} setRule={ setRulesWindowSrc } className="no-print"/>

    <RulesWindow {...props} src={ rulesWindowSrc }></RulesWindow>

    <AllSpells {...props}/>

    <Charsheet05 {...props} />

    <Help />
  </>
  )
}

export default Home