import React from 'react';
import './FloatingWindow.css';

const FloatingWindow = ( props ) => {

  const title = props.title;
  const id = props.id;
  const thisClass = props.className;

  const handleClose = (e) => {
    console.log('HANDLE CLOSE');
    e.target.parentElement.style.display = 'none';
}

  return (

    <div className={ "floatingwindow " + thisClass } id={ id } >
      <button className={"close-spell-detail-mobile button-078"} onClick={handleClose}>X</button>
      { (title !== "") ? <h1>{ title }</h1> : <></> }
      
      { props.children } 

    </div>

  );
};


export default FloatingWindow;