
// --------------------------------------------------------------
// roll_dice function
//
// returns an array of nrDice die rolls, on a nrSides-sided dice
//
// --------------------------------------------------------------

function roll_dice(nrDice = 3, nrSides = 6) {
    let rolls = [];

    for (let i=0; i < nrDice; i++ ) {

        rolls.push( 1 + Math.floor( Math.random() * nrSides ) );

    }

    return rolls;
}



export default roll_dice