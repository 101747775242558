import React, { useState, useEffect } from 'react';

// import './NavBarButton.css'

import Dropdown from '../common/Dropdown'

import LOAD_CHAR from '../Charsheet0/helpers/load_char.js'

// ----------------------------------------------------------------------------------
//                         Description
//
//     RollDiceButton is a temporary placeholder for now. Will eventually navigate to a new page
//  
//     div == .navbaritem
//       button == #nav-menu-rules .nav-menu-button
//         menu items == .menu .spells-menu > li > button
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function RulesButton(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------
    let [currentChar, setChar] = props.state;

    // -------- local state variables --------

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {    

        }

        DoSomething();

    }, [,]); //useEffect

    // ---------- return html (jsx) ----------


    const handleClick = (e) => {

        let rollDiceDiv = document.getElementById('roll-dice');
        
        // if ( rollDiceDiv.style.visibility === 'hidden' ) { rollDiceDiv.style.visibility = 'visible'; }
        // else { rollDiceDiv.style.visibility = 'hidden'; }

        if ( rollDiceDiv.style.display === '' ) { rollDiceDiv.style.display = 'none' }
        if ( rollDiceDiv.style.display === 'none' ) { rollDiceDiv.style.display = 'flex' }
        else { rollDiceDiv.style.display = 'none' }
      }

    return (
        <div className='navbaritem'>
            <button id="nav-menu-roll" className="nav-menu-button" onClick= { handleClick }>Roll Dice</button>
        </div>
    )

    };  // end of function

    export default RulesButton