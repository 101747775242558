import React, { useState, useEffect } from 'react';

import RollDie from './RollDie.js'

// ----------------------------------------------------------------------------------
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function Index(props) {

    // ---------- return html (jsx) ----------

    return (
        <RollDie {...props} />
    )

    };  // end of function

    export default Index