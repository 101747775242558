import React, { useState, useEffect, useContext } from 'react';

import Dropdown from '../common/Dropdown'

import LOAD_CHAR from '../Charsheet0/helpers/load_char2.js'
import SAVE_CHAR from '../Charsheet0/helpers/save_char.js'
import flatten from '../Charsheet0/helpers/flatten.js'
import unflatten from '../Charsheet0/helpers/unflatten.js'

import { UserDataContext } from "../../App.js";

// ----------------------------------------------------------------------------------
//                         Description
//
//     What does NavBarButton do?
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function LoadSaveButton(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------
    let [charData, setCharData, currentChar, setChar ] = props.state;
    const [userData, setUserData] = useContext(UserDataContext);
    var spellCheckArray=props.spellCheckArray;

    // -------- local state variables --------

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {    

        }

        DoSomething();

    },); //useEffect

    // ---------- return html (jsx) ----------

    const options = ['Load', 'Save', 'Save Backup'];

    const handleMenu = async function (selection) {

      switch( options[selection] ) {

        case('---'):
          break;

        case('Load'):
          console.log('CBUTTON currentChar ',currentChar)
          handleLoad(currentChar);
          break;
        
        case('Save'):
          let cs = unflatten(charData);
          console.log (cs);

        // add spell check data - added in v0.4 data e.g. chardata.spells.mySpk11
        if (spellCheckArray) {
          for (let i=11; i<=55; i++) {
            const spellCheckVarName = "mySpk" + String(i)
            cs.spells[spellCheckVarName] = spellCheckArray[i][0]
          }
        }

          if ( userData.loggedIn ) { 
            SAVE_CHAR(cs, cs.name, cs.campaign )
          } else {
              alert('Log in required\n\nSettings > Log In')
          }
          break;
        
        case('Save Backup'):
          let cs2 = unflatten(charData);
          console.log ('Save Backup ', cs2);
          
          break;  

        default:
          handleLoad(options[selection]);
          break;
      }
    }

    const handleLoad = async ( charname = '' ) => {

      let new_data;
      
      if ( charname === '' ) {
          new_data = await LOAD_CHAR('', setCharData, true);

          const flatCharData = flatten(new_data)
          
          setCharData(flatCharData)

      // set spell check data
      if (spellCheckArray) {
        for (let i=11; i<=55; i++) {
          const spellCheckVarName = "mySpk" + String(i)

          // the second item in the Array is the update function
          let updateSpellCheck = spellCheckArray[i][1]
          
          const spellChecksThis = flatCharData[spellCheckVarName]
          
          // set the data if it exists
          if (typeof spellChecksThis !== 'undefined') {
            updateSpellCheck( spellChecksThis )

          } else {
          // set to Empty if no data
            console.log('spellCheck data missing')
            updateSpellCheck(['Empty','Empty','Empty','Empty','Empty','Empty'])
          }
            
        }
      }

      
        } else {
          new_data = await LOAD_CHAR(charname, setCharData, true);
          setCharData(flatten(new_data))
          setChar(new_data['name'])
      }
    }

    return (
        <>
        <Dropdown
            trigger = {<button id="nav-menu-characters" className="cf-load-but no-print">Load/Save</button>}
            menu = { options.map ( ( option, idx) => 
                { return <button onClick={()=>handleMenu(idx)}>{option}</button>}
            )}
            divClass='character-dropdown-full'
            menuClass='characters-menu-full character-menu' 
        />
        </>
    )

    };  // end of function

    export default LoadSaveButton