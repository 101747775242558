import React, { useEffect } from 'react';


import './Newsletter.css'

function Newsletter(props) {
  useEffect( () => {

    async function DoSomething () {    
      document.getElementsByTagName('body')[0].style.backgroundImage = 'url(./img/bg-01-lighter.jpg)';
      // background-image: url('./img/bg-01.jpg');
    }

    DoSomething();

}, ); //useEffect

  return (
  <>
    {/* <NavBar {...props} className="no-print"/> */}
    <div id="newsletter" >  
      <h1>Elemental Greyhawk Newsletter #1<br /> April 21, 2023</h1>

      <h2 className="new">Scheduling</h2>
      <p>Targeting <span style={{ color: 'rgb(150,0,0)', fontWeight: 'bold'}}>SAT MAY 6th</span> for the big event - DragonBreath, RockDoc & Yarg have it penciled in, Rampage is tbd</p>

      <h2>Rule Updates</h2>
      <p>New rule: going forward, there is a 16% chance of an ability score going up when characters level up. Good luck!</p>
      <img className="newsimage" src='/img/level_up_stats.png' alt=""/>

      <h2>The Story</h2>
      <p>1. Charles, Paul, and group venture forth from Gallows Corner in the Kron Hills, in order to find adventure and rid the world of evil. They come to the town of Hommelet, where they discover that a Moathouse ouside of town is the home of an evil cult who is stirring up trouble.</p>
      <p>2. The party is captured by the cultists, with the exception of the elf thief Spiro, who races back to town to get help. He manages to rally the townsfolk to take rapid action as they send their forces to recover Charles & Paul. The ensuing battle is epic and our heroes are rescued, but the town's head Druid is killed by an assissin's arrow</p>
      <p>3. The cult leader and the assassin are brought back to Hommelet for justice. The hanging becomes a public spectacle presided over by the Count of Verbobonc. Our party, particularly Spiro, are declared heroes of Hommelet</p>
      <p>4. During the post-hanging celebration, 10 people in town are assassinated, including the town Cleric and several people within the Count's entourage. It's clear that the evil has not been defeated, so our group continues to the pirate town of Nulb to investigate further.</p>
      <p>5. In Nulb, the party meets up with the Count's agents Otto and Y'Dey, where they learn of the nearby Temple of Elemental Evil, which had terrorized the area 70 years ago, but has not been heard from since.</p>
      <p>6. The party ventures into the Temple, and quickly realizes it is very active. They manage to charm and capture a group of Earth cultists, with the help of their Druid NPC Rey, who is there to exact vengeance for the death of Hommelet's druid. Unfortunately, the hero Spiro is killed by a lightning bolt blast. RIP Spiro</p>
      <p>7. At this time, the party is on the way back to Verbobonc to interrogate the cultists and gain support from the Count for a major offensive. They're going to need help to take out the very active evil of the Temple. The interrogation scene is where our adventure begins the next time we play.</p>
      <p>8. Meanwhile, Tonk & company are on a quest to recover a missing chalice, which Charles' church is supposed to have, but has gone missing. On their way to Verbobonc to investigate, they clear out a crypt and manage to destroy the level 8 spirit haunting the crypt. Davbin shows his worth by being an excellent bowman.</p>
      <p>9. Edward, Lanora and Yarg have a different story. They were in an alternate reality and got involved in a fight with a Lich, who created a temporal vortex which sent them back in time to their younger days. </p><p>Edward is a guard in the Count's service, Lanora is a specialist in the Druid order (as the only Druid/Thief), and Yarg, as always, is Lanora's faithful protector. </p>
      <p>Lanora will take Rey's place as the Druid's representative on this quest, as Rey must get back to Hommelet to be the new town druid. </p>
      <p>10. Lastly, Martin is a young mage who is apprenticed to the Court Wizard in Vebobonc. He is the cousin and friend of Edward, and the Count will surely send the two of them along to help. </p>
      <p>11. <u>It has now been about a month</u> since the cultists were captured. The Temple inhabitants surely know their cover is broken, and the Count will soon know the problem is bigger than anyone thought. </p>
      <p id="last">It's time to rally a large force and get to the real job. No one knows what's at the bottom of the Temple, since 70 years ago, they were only able to contain the evil rather than destroy it. <b>What is down there, and how can it be defeated?</b> </p>


    </div>
  </>
  )
}

export default Newsletter