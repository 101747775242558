import React from 'react';

import CharsheetFull from './CharsheetFull.js'

// ----------------------------------------------------------------------------------
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function Index(props) {

    // ---------- return html (jsx) ----------

    return (
        <CharsheetFull {...props} />
    )

    };  // end of function


export default Index