import React, { useState, useEffect, useContext } from 'react';

import Dropdown from '../common/Dropdown'

// import LOAD_CHAR from '../Charsheet0/helpers/load_char2.js'
import GET_DATA from '../Charsheet0/helpers/get_data.js'
import LOCAL_BACKUP from '../Charsheet0/helpers/download-code.js'

import { UserDataContext } from "../../App.js";

// ----------------------------------------------------------------------------------
//                         Description
//
//     What does NavBarButton do?
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function CharactersButton(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------
    let [currentChar, setChar] = props.state;
    const [userData, setUserData] = useContext(UserDataContext);

    // -------- local state variables --------

    // ---------- event handlers -------------
    var characters = ['blank', 'Roll New', 'Save Local Backup', '---', 'Grok-Tar', 'Rae', 'Kendra', 'Welcome to ADM'];

    characters = [  'Charles', 'Paul',
                    'Edward the Enforcer', 'Martin', 
                    'Yarg Smashskull', 'Lanora Snugglebottom',  
                    'Aeoni', 'Mavis',
                    'Tonk', 'Davbin', 
                    'Rygar', '---',
                     
                    'Pete', 'Eli', 'Trunk', 'Trea', 'Zum', 'Fonk', 'Gerthmig', '---', 
                    'Sylonnus', 'Grok-Nar', '---',
                    'blank', 'Roll New', 'Save Local Backup'];
    
    if (userData.loggedIn && userData.username === "Doc Sonic") {
      characters = ['test', 'test2', 'Charles', 'Paul',
                    'Edward the Enforcer', 'Martin', 
                    'Yarg Smashskull', 'Lanora Snugglebottom',  
                    'Aeoni', 'Mavis',
                    'Tonk', 'Davbin', 
                    'Rygar', '---',
                     
                    'Pete', 'Eli', 'Trunk', 'Trea', 'Zum', 'Fonk', 'Gerthmig', '---', 
                    'Sylonnus', 'Grok-Nar', '---',
                    'blank', 'Roll New', 'Save Local Backup'];
    } 

    if (userData.loggedIn && userData.username !== "Doc Sonic") {
      characters = ['Yarg Smashskull', 'Lanora Snugglebottom', '---', 
                    'Charles', 'Paul', '---', 
                    'Edward the Enforcer', 'Martin', '---',
                    'Tonk', 'Davbin', '---',
                    'Aeoni', 'Mavis', '---',
                    'Eli', 'Trunk', 'Trea', 'Zum', 'Fonk', 'Gerthmig', '---',
                    'blank', 'Roll New', 'Save Local Backup'];
    }

    if (userData.loggedIn && userData.username === "DragonBreath") {
      characters = ['Edward the Enforcer', 'Martin', '---',
                    'Charles', 'Paul', 
                    'Tonk', 'Davbin', 
                    'Yarg Smashskull', 'Lanora Snugglebottom', 
                    'Aeoni', 'Mavis', '---',
                    'Eli', 'Trunk', 'Trea', 'Zum', 'Fonk', 'Gerthmig', '---', 
                    'blank', 'Roll New', 'Save Local Backup'];
    } 

    

    const handleMenu = (selection) => {

        // the final selection item is search
        if ( characters[selection] === 'Roll New' ) { 
            handleFuture();
        }
  
        // for all other items
        else {

          if ( characters[selection] === 'Save Local Backup' ) {

            var outputfile = prompt("Character will be saved to json file on your device. Can be uploaded later by GM in case of emergency.\nEnter filename:", currentChar );
                if (outputfile === null || outputfile === "") {
                    let txt = "User cancelled the prompt.";
                    console.log(txt); }
                else {
                    let chardata = {};
                    chardata.name = currentChar;
                    chardata.version = 0.3;
                    chardata.chardata = GET_DATA();
                    chardata.name = currentChar;
                    outputfile = outputfile + ".JSON"
                    LOCAL_BACKUP(JSON.stringify(chardata, null, '\t'), outputfile, 'text/json')
                }
          }
          
          else if ( characters[selection] !== '---')  {
            // LOAD_CHAR(characters[selection], setChar, false);
            console.log('SET CHAR button click')
            setChar(characters[selection]);
          }
        }
      }

      const handleFuture = () => {
        alert('Future feature');
      }

      
    // ---------- return html (jsx) ----------
    return (
        <>
        <Dropdown
            trigger = {<button id="nav-menu-characters" className="nav-menu-button">Characters</button>}
            menu = { characters.map ( ( character, idx) => 
                { return <button onClick={()=>handleMenu(idx)}>{character}</button>}
            )}
            divClass='navbaritem'
            menuClass='menu character-menu'  
        />
        </>
    )

    };  // end of function

    export default CharactersButton