import React from 'react';
import FloatingWindow from './common/FloatingWindow';

const RulesWindow = ( props ) => {

  // get props
  // globalState = [ currentChar, setChar, loggedIn, layout, setLayout, currentSpellArray, currentSpellDataArray, allSpellsArray ];

  const src=props.src;
  const layout = props.state[3] || "full"
  const classAppend = "-" + layout
  // const classAppend='-mobile'

  return(
    <FloatingWindow title={src.title} id="rulesWindow" className={"rules"+classAppend}>
      <img id={"rules-img"+classAppend} src={src.source} alt={ props.title + " rules"} />
    </FloatingWindow>
  )
};

export default RulesWindow;
