import axios from 'axios';

import spell_translate from './spell_translate.js'

const charsheetAPI = 'https://2gy03m48ej.execute-api.us-west-2.amazonaws.com/default/charsheet-access'
const spellTable = 'Spells-Database-1E'

var spellClass = 'Mage';

async function Get01 ( URL, Header = {} ) {
    const res = await axios.get( URL, Header );
    return res;
}

async function FETCH_SPELL ( spellName, classChoices = ['Cleric, Druid, Mage, Illusionist, MagicItem, Potion'] ) {

    // error out if spellName is empty
    if (spellName.length === 0) { spellName = "no spell provided"; return "error" }
    

    // use translation table to look up alternate spellings
    // let fullSpellName = await(spell_translate(spellName));
    let fullSpellName = spellName

    // if there in no alternate spelling, use the original
    if ( fullSpellName === 'Spell not found' | fullSpellName === 'Error getting spell lookup' ){
        fullSpellName = spellName;
    }


    // remove characters after + or (
    //fullSpellName = fullSpellName.replace(/^\+\d+\s*/, '');
    //fullSpellName = fullSpellName.replace(/(\+.*|\(.*|\d+\s*)?\s*$/, '').trim();
    
    console.log("FETCH_SPELL>> " + fullSpellName )


    // get character data from dynamoDB
    let thisSpell = {}
    spellClass = classChoices[0];

    // FIRST attempt
    try {
        console.log('try first class:', spellClass );
        thisSpell = await Get01( charsheetAPI + '?TableName=' + spellTable + '&Class=' + spellClass + '&Name=' + encodeURIComponent(fullSpellName) );
    } catch(e) {
        console.error(e);
        return "error"
    } finally {

        // SECOND attempt
        if (thisSpell.data === "" ) {
            spellClass = classChoices[1];
            console.log('try second class:', spellClass );
            // console.log( charsheetAPI + '?TableName=' + spellTable + '&Class=' + spellClass + '&Name=' + encodeURIComponent(fullSpellName) )
            try {
                thisSpell = await Get01( charsheetAPI + '?TableName=' + spellTable + '&Class=' + spellClass + '&Name=' + encodeURIComponent(fullSpellName) );
            } catch(e) {
                console.error(e);
                return "error"
            } finally {

                // THIRD attempt
                if (thisSpell.data === "" ) {
                    spellClass = classChoices[2];
                    console.log('try third class:', spellClass );
                    try {
                        thisSpell = await Get01( charsheetAPI + '?TableName=' + spellTable + '&Class=' + spellClass + '&Name=' + encodeURIComponent(fullSpellName) );
                    } catch(e) {
                        console.error(e);
                        return "error"
                    }
                }
            }
        }    
    }

    return thisSpell.data
    

}

export default FETCH_SPELL