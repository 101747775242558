import React, { useState, useEffect } from 'react';

import FlexiGrid from '../common/Flexigrid/Flexigrid.js';

import './CharsheetLong.css'

import CharactersButton from './CharactersButton.js'; 

// ----------------------------------------------------------------------------------
//                         Description
//
//     CharsheetLong is a single-column character view, with extremely compressed data
//       fields and very small fonts.
//     The most common use is to display multiple characters side-by-side for comparison,
//       for example when the user selects 'View N characters' on the Charsheet05 page
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function CharsheetLong(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------

    // these are not currently used, since they are the character data for the single-character view
    const [currentChar, setChar] = props.state;
    const charData = props.data;

    // charID is used to ID the main div, which will be id="{charID}-div"
    const thisCharID = props.charID || '0'

    // -------- local state variables --------
    const [data, setData] = useState({})

    // -------- local state variables --------
    // const [data, setData] = useState({})
    //const data = currentChar.data;
    //const setData = setChar;

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {    

        }

        DoSomething();

    },); //useEffect

    // ---------- return html (jsx) ----------

    // define the grid for this layout
    const cells_one_column = { 
        name:       [1, 10, 1, 4],
        myClass:    [1, 8, 4, 6],
        myLevel:    [8, 10, 4, 6],
        myRace:     [1, 6, 6 ,8],
        myHei:   [6, 8 , 6 ,8],
        myWei:   [8, 10, 6, 8],
        myAlign:    [1, 3, 8, 10],
        myDeity:    [3, 10, 8, 10],
        myStr:      [1, 3, 10, 12],
        myStrBon:   [3, 6, 10, 12],
        myInt:      [1, 3, 12, 14],
        myIntBon:   [3, 6, 12, 14],
        myWis:      [1, 3, 14, 16],
        myWisBon:   [3, 6, 14, 16],
        myDex:      [1, 3, 16, 18],
        myDexBon:   [3, 6, 16, 18],
        myCon:      [1, 3, 18, 20],
        myConBon:   [3, 6, 18, 20],
        myCha:      [1, 3, 20, 22],
        myChaBon:   [3, 6, 20, 22],
        MovTxt:     [ 6, 8, 10, 12 ],
        myMov:      [ 8, 10, 10, 12],
        ToHitTxt:   [6, 8, 12, 14],
        myToHit:    [8, 10, 12, 16],
        myNatt:     [6, 8, 14, 16],
        ACTxt:    [6, 8, 16, 20],
        myAC:       [8, 10, 16, 20],
        ACbaseTxt: [6, 9, 20, 22],
        myRear:   [9, 10, 20, 22],
        myHPbase:  [1,3,22,26],
        myHPcurr: [3,7,22,26],
        myHPnote: [7,10,22,26],

        myWeap1: [1,5,26,28],
        myWth1:   [5,6,26,28],
        myWdb1:  [6,7,26,28],
        myWdmg1: [7,10,26,28],

        myWeap2: [1,5,28,30],
        myWth2:   [5,6,28,30],
        myWdb2:  [6,7,28,30],
        myWdmg2: [7,10,28,30],

        myWeap3: [1,5,30,32],
        myWth3:   [5,6,30,32],
        myWdb3:  [6,7,30,32],
        myWdmg3: [7,10,30,32],

        myWeap4: [1,5,32,34],
        myWth4:   [5,6,32,34],
        myWdb4:  [6,7,32,34],
        myWdmg4: [7,10,32,34],

        myWeap5: [1,5,34,36],
        myWth5:   [5,6,34,36],
        myWdb5:  [6,7,34,36],
        myWdmg5: [7,10,34,36],

        myAb1:  [1,2,36,38],
        myArm1: [2,10,36,38],
        myAb2:  [1,2,38,40],
        myArm2: [2,10,38,40],
        myAb3:  [1,2,40,42],
        myArm3: [2,10,40,42],
        myAb4:  [1,2,42,44],
        myArm4: [2,10,42,44],
        myAb5:  [1,2,44,46],
        myArm5: [2,10,44,46],

        SavTxt1: [1,3,46,48], SavTxt3: [6,8,46,48],
        mySav1:  [3,4,46,48], mySav3:  [8,9,46,48],
        mySbon1: [4,5,46,48], mySbon3: [9,10,46,48],

        SavTxt2: [1,3,48,50], SavTxt5: [6,8,48,50],
        mySav2:  [3,4,48,50], mySav5:  [8,9,48,50],
        mySbon2: [4,5,48,50], mySbon5: [9,10,48,50],

        SavTxt6: [1,2,50,52], SavTxt4: [6,8,50,52],
        mySbon6: [2,6,50,52], mySav4:  [8,9,50,52],
                              mySbon4: [9,10,50,52],

        myFol1: [1,10,52,54], myFol2: [1,10,54,56],

        myTsp1: [1,3,56,57], myNsp1: [3,6,56,57],
        mySp11: [1,6,57,58], mySp12: [1,6,58,59], mySp13: [1,6,59,60], mySp14: [1,6,60,61], mySp15: [1,6,61,62], mySp16: [1,6,62,63],

        myTsp2: [6,8,56,57], myNsp2: [8,10,56,57],
        mySp21: [6,10,57,58], mySp22: [6,10,58,59], mySp23: [6,10,59,60], mySp24: [6,10,60,61], mySp25: [6,10,61,62], mySp26: [6,10,62,63],
        
        myTsp3: [1,3,63,64], myNsp3: [3,6,63,64],
        mySp31: [1,6,64,65], mySp32: [1,6,65,66], mySp33: [1,6,66,67], mySp34: [1,6,67,68], mySp35: [1,6,68,69],
        
        myTsp4: [6,8,63,64], myNsp4: [8,10,63,64],
        mySp41: [6,10,64,65], mySp42: [6,10,65,66], mySp43: [6,10,66,67], mySp44: [6,10,67,68], mySp45: [6,10,68,69],

        myTsp5: [1,3,69,70], myNsp5: [3,6,69,70],
        mySp51: [1,6,70,71], mySp52: [1,6,71,72], mySp53: [1,6,72,73], mySp54: [1,6,73,74],

        myMag1: [1,10,74,75], myMag2: [1,10,75,76], myMag3: [1,10,76,77], myMag4: [1,10,77,78], myMag5: [1,10,78,79],
        myOth1: [1,10,79,81], myOth2: [1,10,81,83], myOth3: [1,10,83,85], myOth4: [1,10,85,87], myOth5: [1,10,87,89],  
        
        myQpo1: [1,2,89,90], myPot1: [2,10,89,90], 
        myQpo2: [1,2,90,91], myPot2: [2,10,90,91], 
        myQpo3: [1,2,91,92], myPot3: [2,10,91,92], 
        myQpo4: [1,2,92,93], myPot4: [2,10,92,93], 
        myQpo5: [1,2,93,94], myPot5: [2,10,93,94], 
        myQpo6: [1,2,94,95], myPot6: [2,10,94,95],

        myRabi: [1,10,95,101], myAbi: [1,10,101,107], myGold: [1,10,107,109], myXP: [1,10,109,112], myXPbonus: [1,4,112,113], myXPnext: [6,10,112,113]
    };

    // <button className='no-print' style={{position:'absolute',top: '16px', left: '120px', fontSize: '8px', width: '14px', paddingTop: '1', zIndex: 120 }}>S</button>

    return (
        <>
            <div id={thisCharID + '-div' } style={{position:'relative', margin:0, padding:0, }}>
                <FlexiGrid
                    rowSize = '8px'
                    colSize = '15px'
                    id = {thisCharID+'-grid-sm'}
                    classRoot = 'cl'
                    cells = { cells_one_column }
                    data = { data }
                    onDataChange = {setData}
                    boxes={{ box1: [ 1,10,1,10 ], box1A: [1,10,1,4], box2: [ 1,6,10,22 ], box3: [ 6,10,10,22 ], 
                             boxHP: [1,10,22,26], boxWeap: [1,10,26,36], boxArm: [1,10,36,46], boxSav: [1,10,46,52],
                             boxFol: [1,10,52,56], boxSp1: [1,6,56,63], boxSp2: [6,10,56,63], boxSp3: [1,6,63,69], boxSp4: [6,10,63,69], boxSp5: [1,6,69,74], boxSp6: [6,10,69,74],
                             boxMag: [1,10,74,79], boxItems: [1,10,79,89], boxPots: [1,10,89,95], boxRace: [1,10,95,101],
                             boxOther: [1,10,101,107], boxGold: [1,10,107,109], boxXP: [1,10,109,113]
                        }}
            />

                <CharactersButton className='no-print' state={[data, setData]}/>
                
            </div>
        </>
    )

    };  // end of function

    export default CharsheetLong

/* Example usage (from Charsheet05)
    <CharsheetLong state={props.state} data={char1data} setdata={setChar1Data} charID='char1' />
    <CharsheetLong state={props.state} data={char2data} setdata={setChar2Data} charID='char2' />
*/