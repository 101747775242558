import React, { useState, useEffect } from 'react';

import Charsheet from './Charsheet0.js'
// import Charsheet05 from './Charsheet05.js'

// ----------------------------------------------------------------------------------
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function Index(props) {

    // ---------- return html (jsx) ----------

    return (
        <Charsheet {...props} />
    )

    };  // end of function

    export default Index