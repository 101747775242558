import React, { useState, useEffect } from 'react';

import './RollDice.css'

import RollDie from '../RollDie';
import roll_dice from './helpers/roll_dice';

// ----------------------------------------------------------------------------------
//                         Description
//
//     What does RollDice do?
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function RollDice(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------
    const thisClass = props.class || 'mobile';

    // -------- local state variables --------
    const [ nrDice4, setNrDice4 ] = useState(0);
    const [ nrDice6, setNrDice6 ] = useState(0);
    const [ nrDice8, setNrDice8 ] = useState(0);
    const [ nrDice10, setNrDice10 ] = useState(0);
    const [ nrDice12, setNrDice12 ] = useState(0);
    const [ nrDice20, setNrDice20 ] = useState(0);

    const [ rolls4, setRolls4 ]= useState([]);
    const [ rolls6, setRolls6 ]= useState([]);
    const [ rolls8, setRolls8 ]= useState([]);
    const [ rolls10, setRolls10 ]= useState([]);
    const [ rolls12, setRolls12 ]= useState([]);
    const [ rolls20, setRolls20 ]= useState([]);

    const style = props.IDadd || '33';
    let rollDiceID = 'roll-dice';

    if (style) {
        rollDiceID = 'roll-dice-' + style; // e.g. -mobile
    }

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {    

        }

        DoSomething();

    }, [nrDice6]); //useEffect

    // ---------- helper functions -----------

    const sum_dice = ( rolls ) => {

        let total = 0;
        for ( let i in rolls ) {
            total += parseInt( rolls[i] );
        }

        return total;
    }
    // ---------- event handlers -------------

    const resetAll = (e) => {
        setNrDice4(0); setRolls4([]);
        setNrDice6(0); setRolls6([]);
        setNrDice8(0); setRolls8([]);
        setNrDice10(0); setRolls10([]);
        setNrDice12(0); setRolls12([]);
        setNrDice20(0); setRolls20([]);
        document.getElementById('roll-add-this-value').value = 0;
        document.getElementById('roll-grand-tot').innerHTML = 0;
        document.getElementById('roll-avg').innerHTML = 0;
    }

    const rollDice = (e) => {

        let roll4 = roll_dice(nrDice4, 4);
        let roll6 = roll_dice(nrDice6, 6);
        let roll8 = roll_dice(nrDice8, 8);
        let roll10 = roll_dice(nrDice10, 10);
        let roll12 = roll_dice(nrDice12, 12);
        let roll20 = roll_dice(nrDice20, 20);

        setRolls4(roll4);
        setRolls6(roll6);
        setRolls8(roll8);
        setRolls10(roll10);
        setRolls12(roll12);
        setRolls20(roll20);

        let grandTotal = sum_dice(roll4);
        grandTotal += sum_dice(roll6);
        grandTotal += sum_dice(roll8);
        grandTotal += sum_dice(roll10);
        grandTotal += sum_dice(roll12);
        grandTotal += sum_dice(roll20);

        let totalDice = nrDice4;
        totalDice += nrDice6;
        totalDice += nrDice8;
        totalDice += nrDice10;
        totalDice += nrDice12;
        totalDice += nrDice20;

        grandTotal += parseInt(document.getElementById('roll-add-this-value').value);
        document.getElementById('roll-grand-tot').innerHTML = grandTotal;

        document.getElementById('roll-avg').innerHTML = parseFloat( parseInt(100 * grandTotal / totalDice) )/100;

    }

    // ---------- return html (jsx) ----------

    return (
        <div id={'roll-dice'} className={'no-print dice-' + thisClass }>
            <div id='roll-dice-row-1'>
                <button id='roll-all-button' className='button-87 greenie' onClick={ rollDice }>Roll Dice</button>

                <div id='roll-add-this' className='roll-row1-txt'>Add This</div>
                <input type='number' defaultValue='0' id='roll-add-this-value'></input> 

                <div id='roll-avg-txt' className='roll-row1-txt'>Avg</div>
                <div id='roll-avg' className='roll-row1-txt'>3.25</div>

                <div id='roll-grand-txt' className='roll-grand-tot'>Grand Total</div>
                <div id='roll-grand-tot' className='roll-grand-tot'>0</div>

                <button id='reset-all-button' className='button-87' onClick={ resetAll }>Reset All</button>

            </div>

            <div id='roll-dice-row-2'>
                <RollDie NrSides='4' Rolls={ rolls4 } NrDice={nrDice4} Set={ setNrDice4 }  />
                <RollDie NrSides='6' Rolls={ rolls6 } NrDice={nrDice6} Set={ setNrDice6 }  />
                <RollDie NrSides='8' Rolls={ rolls8 } NrDice={nrDice8} Set={ setNrDice8 }  />
                <RollDie NrSides='10' Rolls={ rolls10 } NrDice={nrDice10} Set={ setNrDice10 }  />
                <RollDie NrSides='12' Rolls={ rolls12 } NrDice={nrDice12} Set={ setNrDice12 }  />
                <RollDie NrSides='20' Rolls={ rolls20 } NrDice={nrDice20} Set={ setNrDice20 }  />
            </div>

            <div id='rolls-box-title'>ROLLS</div>
            <div id='rolls-box'></div>
        </div>
    )

    };  // end of function

    export default RollDice