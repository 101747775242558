import React from 'react';

import Charsheet05 from './Charsheet05.js'

// ----------------------------------------------------------------------------------
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function Index(props) {

    // ---------- return html (jsx) ----------

    return (
        <Charsheet05 {...props} />
    )

    };  // end of function

    export default Index