import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const OAuth2Handler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // typical URL: http://10.0.0.126:3000/oauth2/discord/#token_type=Bearer&access_token=FG2Jq8auzDIgwHddsYiyy3kLAZ3mjG&expires_in=604800&scope=identify

    const currentPath = window.location.pathname;
    const currentRoute = currentPath.replace(/^\/\d+\.\d+\.\d+\.\d+:\d+/, '');
    
    if (currentRoute !== "/oauth2/callback/") { return; }   // exit this if the route is not oauth2/callback

      const hashParams = new URLSearchParams(window.location.hash.substring(1));
      const accessToken = hashParams.get('access_token');
      console.log(accessToken);
      localStorage.setItem('discord_token', accessToken);

    // Automatically redirect to the main URL ("/") after storing the access token
    navigate("/");

  }, );

  return (
    // this will not display since there is an automatic redirect
    <div id="OAuth2">
      CALLBACK FROM OAUTH2 *confirmed*
    </div>
  );
};

export default OAuth2Handler;