import React, { useState, useEffect } from 'react';

import NavBar from './NavBar.js'

// ----------------------------------------------------------------------------------
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function Index(props) {

    // ---------- return html (jsx) ----------

    return (
        <>
        <NavBar {...props} />
        </>
    )

    };  // end of function

    export default Index