import React, { useState, useEffect } from 'react';

import NavBarMobile from './NavBarMobile.js'

// ----------------------------------------------------------------------------------
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function Index(props) {

    // ---------- return html (jsx) ----------

    return (
        <>
        <NavBarMobile {...props} />
        </>
    )

    };  // end of function

    export default Index