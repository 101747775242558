import React from 'react';

import './Help.css'

// ----------------------------------------------------------------------------------
//                         Description
//
//     What does Help do?
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function Help(props) {

    // ------------ parameters ---------------
    const title = 'Advanced Dungeon Master'
    const item1bold = 'Basic idea'
    const item1 = "While playing, have the site open to view and save changes to your character. Or, choose to print your character using the browser's print function, and update and save later"
    const item2bold = 'Characters'
    const item2 = "Click to pull up the list of characters. Select the character you'd like to view and edit. To create a new character, select blank from the list. To load a character not on the list, use the Load button, and type in the character name"
    const item3bold = 'Save'
    const item3 = "Click to save your character. To change names, type a new name into the prompt"
    const item4bold = 'Spells'
    const item4 = 'Click on a spell name to show a popup with details of that spell. To edit spells, click on any of Spell1 to Spell5. This enables spell edit mode (names will turn green). Click on Spell1 again to exit this mode'
    const item5bold = 'Rules'
    const item5 = "Click to see a list of available rules. Most of these are pdf's which will be downloaded when clicked"

    // ------ inherited state variables ------

    // -------- local state variables --------

    // ---------- event handlers -------------
    
    const handleClose = () => {
        console.log('HANDLE CLOSE');
        document.getElementById('help-menu').style.display = 'none';
    }

    // ---------- return html (jsx) ----------

    return (
        <div id='help-menu' >
            <button id="close-help" onClick={handleClose}>X</button>
            <h1 className='help-head'>How to Use<br />{title}</h1>
            <p><span className='bold'>{item1bold}</span>&nbsp;&nbsp;{item1}</p>
            <p><span className='bold'>{item2bold}</span>&nbsp;&nbsp;{item2}</p>
            <p><span className='bold'>{item3bold}</span>&nbsp;&nbsp;{item3}</p>
            <p><span className='bold'>{item4bold}</span>&nbsp;&nbsp;{item4}</p>
            <p><span className='bold'>{item5bold}</span>&nbsp;&nbsp;{item5}</p>

        </div>
    )

    };  // end of function

    export default Help