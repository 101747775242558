import React, { useState, useEffect } from 'react';

import './AllSpells.css'

import load_spell from './Spell/helpers/load_spell.js'
import spell_translate from './Spell/helpers/spell_translate.js'
import fetch_spell from './Spell/helpers/fetch_spell.js'

import FloatingWindow from './common/FloatingWindow.js';

// ----------------------------------------------------------------------------------
//                         Description
//
//     Shows a list of all spells
//
//     (c) DXA Development 2024
// ----------------------------------------------------------------------------------

function AllSpellsUI(props) {

    // ------------ parameters ---------------
    const all_spells_test = []

    // ------ inherited state variables ------

    // props.state = globalstate = [ currentChar, setChar, loggedIn, layout, setLayout, currentSpellArray, currentSpellDataArray, allSpellsArray ]

    const [ currentSpell, setCurrentSpell ] = props.state[5];
    const [ currentSpellData, setCurrentSpellData ] = props.state[6];
    const [ allSpells, setAllSpells, spellFilter, setSpellFilter ] = props.state[7];
    
    const layout = props.state[4]
    
    // const spellFilter = props.filter || { Class: ['Cleric', 'Druid', 'Mage', 'Illusion'], Lvl: [1,2,3,4,5,6,7,8,9] }
    const UItype = props.UItype || "";

    // -------- local state variables --------
    
    var classAppend = UItype ? '-' + UItype : "";


    // ------------- useEffect ---------------

    useEffect( () => {

      async function DoSomething () {
        getAllSpells()
        console.log('UI All Spells', allSpells )
      }

      DoSomething();

  }, [ ]); //useEffect

    const handleClose = (e) => {
        console.log('HANDLE CLOSE');
        e.target.parentElement.parentElement.style.display = 'none';
    }

    // access list of all spells
    const getAllSpells = async () => {
      let spells = []
      try {
          spells = await allSpells;
      }
      catch(e) {
          console.log('Error loading allSpells',e)}    
      finally {
          if (spells.length !== 0 ) {
            setAllSpells(spells)
            console.log("SPELLS SET ", spells)
          }
      }
    }

    

    // process the user clicking on a spell
    const showSpell = async (spell) => {
      
      let thisSpell = await fetch_spell(spell.Name, [spell.Class] )

      setCurrentSpellData(thisSpell);

      // turn on/off UI elements 
      document.getElementById('all-spells-UI').style.display = 'none';
      document.getElementById('spell-details').style.display = 'grid';
      const mobileSpellUI = document.querySelector("#MobileSpellDetail")
            if ( mobileSpellUI ) mobileSpellUI.style.display = 'block';

      /* this section is removed now that mobileSpellDetail is implemented
      if ( layout !== "mobile")
        window.scrollTo({ top: 0, behavior: 'smooth' });
      else
        window.scrollTo({ top: 800, behavior: 'smooth' });
      */
     
      }
        

    // ---------- return html (jsx) ----------

    return (
      <>
        <FloatingWindow title="Spells" className={"all-spells" + classAppend} id={"all-spells-UI"}>
          {Array.isArray(allSpells) && (
            <>
              { spellFilter.Class.map( (school) => (
                <div className="spells-by-class" key={school}>
                  <h2 className="spell-school-header">{school}</h2>
                  { spellFilter.Lvl.map( (level) => (
                  ( level < 8 ) ? 
                  <>
                    <h3 className="spell-level-header">Level {level}</h3> 
                    <ul className="all-spells-list">
                      { allSpells.map((spell, index) => (
                        ( spell['Class'] === school && spell['Lvl'] === level ) ? <li onClick={() => showSpell(spell)} className="spell-name" key={index}>{spell.Name}</li> : <></>
                      )) }
                    </ul>
                  </> : 
                  ( school === 'Mage' ) ? 
                  <>
                    <h3 className="spell-level-header">Level {level}</h3> 
                    <ul className="all-spells-list">
                      { allSpells.map((spell, index) => (
                        ( spell['Class'] === school && spell['Lvl'] === level ) ? <li onClick={() => showSpell(spell)} className="spell-name" key={index}>{spell.Name}</li> : <></>
                      )) }
                    </ul>
                  </> :
                  <></>
                    ))}
                  
              </div>
              ))}
              
            </>
          )}
        
        </FloatingWindow>
      </>
    );
    
    

    };  // end of function

    export default AllSpellsUI