import React from 'react';

const Dropdown = ({ trigger, menu, divClass, menuClass }) => {

    const [open, setOpen] = React.useState(false);
  
    const handleOpen = () => {
      setOpen(!open);
    };
    
    if (divClass === undefined) {
      divClass = 'dropdown';
    }

    if (menuClass === undefined) {
      menuClass = 'menu';
    }
  
    return (
      <div className={divClass}>
        {React.cloneElement(trigger, {
          onClick: handleOpen,
        })}
        {open ? (
          <ul className={menuClass} >
            {menu.map((menuItem, index) => (
              <li key={index} className="menu-item">
                {React.cloneElement(menuItem, {
                  onClick: () => {
                    menuItem.props.onClick();
                    setOpen(false);
                  },
                })}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  };

  export default Dropdown