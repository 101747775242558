import React, { useState, useEffect } from 'react';
import './NavBarMobile.css'

import NavBarItem from '../NavBarItem';
import NavBarButton from '../NavBarItem/CharactersButton';

// ----------------------------------------------------------------------------------
//                         Description
//
//     What does this do?
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function NavBarMobile(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------

    // -------- local state variables --------
    const [ currentSpell, setCurrentSpell] = useState('Fireball');

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {    

        }

        DoSomething();

    }, [,]); //useEffect

    // ---------- return html (jsx) ----------

    return (
        <>
        <div id="navBarMobile" className="no-print">
            {/* <div id="corporate">Advanced Dungeon Master</div> */}

            <div id="navBarMobileButtonsArea">
                <NavBarItem {...props}>Characters</NavBarItem>
                <NavBarItem {...props}>Rules</NavBarItem>
                <NavBarItem {...props}>Spells</NavBarItem>
                <NavBarItem {...props}>Roll Dice</NavBarItem>
                <NavBarItem {...props}>Settings</NavBarItem>
                <div id="navBarSpacer-mobile" className="no-print"></div>
            </div>

            

        </div>    
        </>
    )

    };  // end of function

    export default NavBarMobile