import { React, useState, useEffect } from 'react';
import './SpellCheckGroup.css';



const SpellCheckGroup = ({ spellChecks, spell_ID }) => {
  const spellStatus = spellChecks[0];
  const setSpellStatus = spellChecks[1];
  
  let [className, setClassName] = useState([]);

  // ------------- useEffect ---------------

  useEffect( () => {

    async function DoSomething () {
      setClassName(spellStatus);
    }

    DoSomething();

}, []); //useEffect

  const getClass = (index, value) => {
    // sets className[index] based on value and the status of nearby items
    if (value!=='Empty') return value
    if (index === 6 && value==='Empty') return 'dim'
    if (value==='Empty' && spellStatus[index+1]==="Empty") return 'hidden'
    if (value==='Empty' && spellStatus[index+1]!=="Empty") return 'dim'
  } 


  const handleCheckboxClick = (index) => {
    const newSpellStatus = [...spellStatus];

    if (newSpellStatus[index] === 'Empty') {
      newSpellStatus[index] = 'Ready';

      if (index > 0) {
        // No state change for 'Dim', handle it through a separate class
      }
    } else if (newSpellStatus[index] === 'Ready') {
      newSpellStatus[index] = 'Used';
    } else if (newSpellStatus[index] === 'Used') {
      newSpellStatus[index] = 'Empty';
      if (index < spellStatus.length - 1 && newSpellStatus[index + 1] === 'Empty') {
        // No state change for 'Dim', handle it through a separate class
      }
    }

    setSpellStatus(newSpellStatus, spell_ID);
  };

  return (

    <>
    <div className="spell-check-group">
      {spellStatus.map((status, index) => (
        <input
          key={index}
          type="checkbox"
          checked={status === 'Used' || status==='Ready'}
          onChange={() => handleCheckboxClick(index)}
          className={`checkbox ${getClass(index,status)}`}
        />
      ))}
    </div>
    </>
    
  );
};

export default SpellCheckGroup;
