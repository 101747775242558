import React from 'react';
import FloatingWindow from './common/FloatingWindow';
import Spell from './Spell';

import './MobileSpellDetail.css'

const MobileSpellDetail = ( props ) => {

  // get props
  // globalState = [ currentChar, setChar, loggedIn, layout, setLayout, currentSpellArray, currentSpellDataArray, allSpellsArray ];
  
  const [ currentSpell, setCurrentSpell ] = props.state[5]
  const [ currentSpellData, setCurrentSpellData ] = props.state[6]
  
  const layout = props.state[3] || "full"
  const classAppend = "-" + layout

  // const classAppend='-mobile'

  return(
    <FloatingWindow title="" id="MobileSpellDetail" className={"rules"+classAppend}>
      <Spell spellname={ currentSpell } spelldata={ currentSpellData } UItype={'mobile'}/>
    </FloatingWindow>
  )
};

export default MobileSpellDetail;