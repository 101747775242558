import React, { useState, useEffect } from 'react';

import DOMPurify from 'dompurify';



import './Flexigrid.css'

// ----------------------------------------------------------------------------------
//                         Description
//
//     Flexigrid is a grid of input cells, defined by props.cells
//
//     cells = { datafield1: [ X1, X2, Y1, Y2 ], datafield2: ... }
//
//     boxes = { box1: [ X1, X2, Y1, Y2 ], box2: ... }
//
//     data = { datafield1: 'value1', datafield2: ... }
//       data is normally tied to a character data state variable
//       when an input field is changed, data is updated using the OnDataChange function
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

// helper function to sanitize the inputs/protect from XSS attacks

const MAXSTRINGLENGTH = 150
const sanitizeInput = (input) => {
  if (input.length > MAXSTRINGLENGTH ) {
    alert('Max field length is 100 characters. Concatenating');
    input = input.slice(0,MAXSTRINGLENGTH+1);
  }
  return DOMPurify.sanitize(input);
};

function Flexigrid(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------

    const rowSize = props.rowSize || '40px';
    const colSize = props.colSize || '300px';
    const gridID = props.id || 'flexi';
    const classRoot = props.classRoot || 'fl';
    const inheritedClasses = props.className || '';
    const cells = props.cells || {};
    const cellsHTML = props.cellsHTML || {};   // not fully implemented yet - has issues
    const data = props.data || {};
    const onDataChange = props.onDataChange || (() => {});
    const boxes = props.boxes || {};

    // -------- local state variables --------

    // -------- start-up code  ---------------

    // ------------- useEffect ---------------

    // ----------- Builder functions ----------

    const htmlItems = Object.entries(cellsHTML).map(([cellHTML]) => { 
      console.log('html items ', cellHTML)
      return (
        <div></div> 
        
      )
        // cellHTML.code;
    });

    const gridItems = Object.entries(cells).map(([cellName, cell ]) => {

        const [colStart, colEnd, rowStart, rowEnd] = cell;
        const value = data[cellName] || '';

        /*---------  Spells need to be EditableLinks  --------------------------
        <EditableLink initial="" id="mySp11" spellUpdaters={props.spellUpdaters}
        */ 
        // textboxes
        if ( cellName.includes('myAbi') || cellName.includes('myRabi') ) {
          return (
            <div
                id = { gridID + '-' + cellName }
                className = { 'flexicell ' + classRoot + '-cell' }
                style = {{
                    width: `${(colEnd - colStart) * parseInt(colSize, 10)}px`,
                    height: '96%',
                    gridRowStart: rowStart,
                    gridColumnStart: colStart,
                    gridRowEnd: `span ${rowEnd - rowStart}`,
                    gridColumnEnd: `span ${colEnd - colStart}`,
                }}
            >
              <textarea
                className = { classRoot + '-charlong-textbox' }
                id = { gridID + '-' + cellName + '-in' }
                // style={{ fontSize: `${fontSize}pt`, }}
                value={value}
                onChange={(event) => {
                    const newData = { ...data };
                    newData[cellName] = sanitizeInput(event.target.value);
                    onDataChange(newData);
                }}
              />
            </div>
            );

        }

        // code to calculate font Size automatically
        // const fontSize = Math.min(parseInt(colSize, 10)/ 1.8, 16);
        // height: `${(rowEnd - rowStart) * parseInt(rowSize, 10)}px`,
        // primary return statement. text input inside of a div

        return (

        <div
            id = { gridID + '-' + cellName }
            className = { 'flexicell ' + classRoot + '-cell' }
            style = {{
                width: `${(colEnd - colStart) * parseInt(colSize, 10)}px`,
                height: '96%',
                gridRowStart: rowStart,
                gridColumnStart: colStart,
                gridRowEnd: `span ${rowEnd - rowStart}`,
                gridColumnEnd: `span ${colEnd - colStart}`,
            }}
        >

          <input
            type="text"
            className = { 'flexiinput ' + classRoot + '-in' }
            id = { gridID + '-' + cellName + '-in' }
            // style={{ fontSize: `${fontSize}pt`, }}
            value={value}
            onChange={(event) => {
                const newData = { ...data };
                newData[cellName] = sanitizeInput(event.target.value);
                onDataChange(newData);
            }}
          />
        </div>
        );
    });

    const boxItems = Object.entries(boxes).map(([boxName, cell]) => {
      let [colStart, colEnd, rowStart, rowEnd] = cell;
      return (
        <div
              className = { 'flexibox ' }
              id = { classRoot + '-' + boxName }
              style = {{
                  gridRowStart: rowStart,
                  gridColumnStart: colStart,
                  gridRowEnd: `span ${rowEnd - rowStart}`,
                  gridColumnEnd: `span ${colEnd - colStart}`,
                  marginRight: '1px',
                  marginTop: '1px'
              }}
        ></div>
      );      
    });


    function getSpans(cell_def) {
        
        let xValues = [];
        let yValues = [];
        
        for (let key in cell_def) {
          let x1 = cell_def[key][0] || 1;
          let x2 = cell_def[key][1] || 1;
          let y1 = cell_def[key][2] || 1;
          let y2 = cell_def[key][3] || 1;

          xValues.push(x1, x2);
          yValues.push(y1, y2);
        }
        let Xspan = Math.max(...xValues) - Math.min(...xValues) ;
        let Yspan = Math.max(...yValues) - Math.min(...yValues) ;

        return [Xspan, Yspan];
      }

    
    
    // ---------- main return JSX ----------
    //

    const [nCols, nRows] = getSpans(cells);
    //gridTemplateRows: `repeat(${nRows},${rowSize})`

    return (

    // div that is a static grid `nCols` wide x `gridItems.length` long
    //
    // boxItems are border lines overlaid on top

    <div
      className = {'flexigrid ' + classRoot + '-grid ' + inheritedClasses} 
      id =  { gridID }
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${nCols}, ${colSize})`
      }}
    >
      {/* {htmlItems} */}
      {gridItems}
      {boxItems}
    </div>
    )

};  // end of function

export default Flexigrid

/* Sample usage. Cells defines the input areas, boxes defines the lines
   
   cells={{ datafield1: [ 1,3,1,2 ], datafield2: [1,3,2,3], ... }}

   [ X1, X2, Y1, Y2] where X is the column number and Y is the row number

   the names of 'datafield1' need to be keys in the data dictionary => data[datafield1]
   boxes input does not have this restriction

  <FlexiGrid
    rowSize = '8px'
    colSize = '15px'
    id = {thisCharID+'-grid-sm'}
    classRoot = 'cl'
    cells = {{ name: [1, 10, 1, 4], myClass: [1, 8, 4, 6], myLevel: [8, 10, 4, 6], ... }}
    data = { data }
    onDataChange = {setData}
    boxes={{ box1: [ 1,10,1,10 ], box1A: [1,10,1,4], box2: [ 1,6,10,22 ], box3: [ 6,10,10,22 ], 
              boxHP: [1,10,22,26], boxWeap: [1,10,26,36], boxArm: [1,10,36,46], boxSav: [1,10,46,52],
              boxFol: [1,10,52,56], boxSp1: [1,6,56,63], boxSp2: [6,10,56,63], boxSp3: [1,6,63,69], boxSp4: [6,10,63,69], boxSp5: [1,6,69,74], boxSp6: [6,10,69,74],
              boxMag: [1,10,74,79], boxItems: [1,10,79,89], boxPots: [1,10,89,95], boxRace: [1,10,95,101],
              boxOther: [1,10,101,107], boxGold: [1,10,107,109], boxXP: [1,10,109,113]
        }}
  />
    */