import axios from 'axios';

// ************************************************
//
// getAllSpells - gets the list of spells from ./tables/all_spells.json

// const charsheetAPI = 'https://2gy03m48ej.execute-api.us-west-2.amazonaws.com/default/charsheet-access'
// const spellTable = 'Spells-Database-1E'

var spellClass = 'Mage';

async function Get01 ( URL, Header = {} ) {
    const res = await axios.get( URL, Header );
    return res;
}

async function GET_ALL_SPELLS ( classChoices = ['Cleric, Druid, Mage, Illusionist, MagicItems'], caller='Unknown' ) {

    let allSpells = {}

    console.log('**** GET ALL SPELLS ****  Caller: ', caller)

    // get the full list from URL
    try {
        allSpells = await Get01('./tables/all_spells.json');
    } catch(e) {
        console.error(e);
    } finally {
        console.log('All Spells:', allSpells.data)
    }

    return allSpells.data
    

}

export default GET_ALL_SPELLS