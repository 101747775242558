import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// ---------- AWS Setup ----------

// https://betterprogramming.pub/how-to-use-aws-dynamodb-in-react-70b55ffff93e


// --------- render root node ----------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  
    <App />
  
);

/*   <React.StrictMode>
    <App />
  </React.StrictMode>
*/

