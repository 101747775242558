import React, { useState, useEffect } from 'react';
import './NavBar.css'

import NavBarItem from '../NavBarItem';
import NavBarButton from '../NavBarItem/CharactersButton';

// ----------------------------------------------------------------------------------
//                         Description
//
//     What does this do?
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function NavBar(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------

    // -------- local state variables --------

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {    

        }

        DoSomething();

    }, [,]); //useEffect

    // ---------- return html (jsx) ----------

    return (
        <>
        <div id="NavBar" className="no-print">
            <div id="corporate">Advanced Dungeon Master</div>

            <div id="navBarButtonsArea">
                <NavBarItem {...props}>Characters</NavBarItem>
                <NavBarItem {...props}>Rules</NavBarItem>
                <NavBarItem {...props}>Spells</NavBarItem>
                <NavBarItem {...props}>Roll Dice</NavBarItem>
                <NavBarItem {...props}>Settings</NavBarItem>
            </div>

            

        </div>    
        </>
    )

    };  // end of function

    export default NavBar