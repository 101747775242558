import axios from 'axios';

import load_stats from './load_stats.js'

import Cookies from "js-cookie";

var txt = ""

const charsheetAPI = 'https://2gy03m48ej.execute-api.us-west-2.amazonaws.com/default/charsheet-access'
const charsheetTable = 'charsheet-test'
const campaign = 'Greyhawk20'

async function Get01 ( URL, Header = {} ) {
    const res = await axios.get( URL, Header );
    console.log({ RESULT: res });
    return res;
}

    // Get01 ( 'https://api.weather.gov/stations/KLVK/observations' )
    
    // const PurpleAirHeader = { headers: {stationid: 54551 } };
    // Get01 ('https://fstwboor08.execute-api.us-west-2.amazonaws.com/getAirQuality', PurpleAirHeader );

async function LOAD_CHAR ( charname, setChar, ask=true ) {
    
    var loadfile = charname;

    if (ask) {
        if (charname === undefined) {
            loadfile = prompt("Load file:", charname );
        
            if (loadfile === null || loadfile === "") {
                    txt = "User cancelled the prompt.";
                    console.log(txt);
                    return 'User Cancelled'; }
            else {  loadfile = loadfile + "" };  }

        else { 
            loadfile = prompt("Load file:", charname );

            if (loadfile === null || loadfile === "") {
                txt = "User cancelled the prompt.";
                console.log(txt);
                return 'User Cancelled'; }
            else {  loadfile = loadfile + "" };  
            // loadfile = charname;
        }
    }    

    // SHORTCUTS for our favorite characters

    if (loadfile === 'Yarg') { loadfile = 'Yarg Smashskull'; }
    if (loadfile === 'Lanora') { loadfile = 'Lanora Snugglebottom'; }
    if (loadfile === 'Gerald') { loadfile = 'Gerald the Bold'; }
    if (loadfile === 'Sylonnus') { loadfile = 'Sylonnus Transvexus'; }
    if (loadfile === 'Edward') { loadfile = 'Edward the Enforcer'; }
    
    console.log("LOAD_CHAR>> " + loadfile )

    // get character data from dynamoDB API

    console.log(charsheetAPI + '?TableName=' + charsheetTable + '&campaign=' + campaign + '&name=' + loadfile)
    const charDataFromAPI = await Get01( charsheetAPI + '?TableName=' + charsheetTable + '&campaign=' + campaign + '&name=' + loadfile );

    // handle the results of the API call

    if ( charDataFromAPI.data !== "" ) { 
        
        // load successful, store the name as a cookie

        Cookies.set("currentChar", JSON.stringify(charDataFromAPI.data.name), { expires: 30 } );
        console.log('loadchar2 cookie set',charDataFromAPI.data.name);


    } else {
        // error with the data fetch

        console.log('LOAD: name not found or no data returned');
    }

    // the old way of doing this. works with Charsheet0 but not CharsheetFull
    // load_stats(charDataFromAPI.data, setChar );
    
    // return the character data
    
    console.log('CHAR data received ', charDataFromAPI.data)
    return charDataFromAPI.data


}

export default LOAD_CHAR