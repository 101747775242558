function flatten (chardata) {

    // takes chardata and flattens it

    let flatData = { name: chardata.name }

    let sectionData = chardata.chardata.chars
    for ( var key in sectionData ) {
        flatData[key] = sectionData[key];
    }

    sectionData = chardata.chardata.stats;
    for ( key in sectionData ) {
        flatData[key] = sectionData[key];
    }

    sectionData = chardata.chardata.weapons; 
    for ( key in sectionData ) {
        flatData[key] = sectionData[key];
    }

    sectionData = chardata.chardata.armor;
    for ( key in sectionData ) {
        flatData[key] = sectionData[key];
    }

    sectionData = chardata.chardata.saves;
    for ( key in sectionData ) {
        flatData[key] = sectionData[key];
    }

    sectionData = chardata.chardata.spells;
    for ( key in sectionData ) {
        flatData[key] = sectionData[key];
    }

    sectionData = chardata.chardata.magic;
    for ( key in sectionData ) {
        flatData[key] = sectionData[key];
    }

    sectionData = chardata.chardata.items;
    for ( key in sectionData ) {;
        flatData[key] = sectionData[key];
    }

    sectionData = chardata.chardata.potions;
    for ( key in sectionData ) {;
        flatData[key] = sectionData[key];
    }

    sectionData = chardata.chardata.followers;
    for ( key in sectionData ) {
        flatData[key] = sectionData[key];
    }

    sectionData = chardata.chardata.abilities;
    for ( key in sectionData ) {;
        flatData[key] = sectionData[key];
    }

    // Add labels
    flatData.StrTxt = "STR";
    flatData.IntTxt = "INT";
    flatData.WisTxt = "WIS";
    flatData.DexTxt = "DEX";
    flatData.ConTxt = "CON";
    flatData.ChaTxt = "CHA";
    flatData.MovTxt = "Move";
    flatData.HeiTxt = "Height";
    flatData.WeiTxt = "Weight";
    flatData.StatBuffer = "Combat";
    
    flatData.ClassTxt = "CLASS";
    flatData.LvlTxt = "LEVEL";
    flatData.XPTxt = "XP";
    flatData.NXPTxt = "NextXP";
    flatData.GoldTxt = "GOLD";
    flatData.RaceTxt = "RACE";
    flatData.AliTxt = "ALIGN";
    flatData.DeiTxt = "DEITY";

    flatData.HPTxt = "HP";
    flatData.ToHitTxt = "ToHit";
    flatData.ACTxt = "AC";
    flatData.BaseTxt = "Base";
    flatData.RearTxt = "Rear";
    flatData.NattTxt = "#Att";

    flatData.ACbaseTxt = "base";
    flatData.ArmTxt = "Armor";
    flatData.WeaTxt = "Weapon";
    flatData.WthTxt = "to Hit";
    flatData.WdbTxt = "Dbon";
    flatData.WdgTxt = "Damage";

    flatData.PotTxt = "Potions";
    flatData.MagTxt = "Magic Items";
    flatData.OthTxt = "Other";
    flatData.FolTxt = "Followers";
    flatData.RacTxt = "Abilities";

    flatData.SaveTxt = "Saving Throws";
    flatData.SavTxt1 = "Poison";
    flatData.SavTxt2 = 'Poly';
    flatData.SavTxt3 = 'Wand';
    flatData.SavTxt4 = 'Magic';
    flatData.SavTxt5 = 'Breath';
    flatData.SavTxt6 = 'Spc';
    flatData.SavTxt6F = "Special";

    flatData.Sp1Txt = "Spell 1";
    flatData.Sp2Txt = "Spell 2";
    flatData.Sp3Txt = "Spell 3";
    flatData.Sp4Txt = "Spell 4";
    flatData.Sp5Txt = "Spell 5";

    flatData.AbiTxt = "More";

    // console.log({flatData: flatData})

    return flatData

}

export default flatten