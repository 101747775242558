import React, { useState, useEffect, useContext } from 'react';

import './CharNameMobile.css'

import { UserDataContext } from "../App.js";

// ----------------------------------------------------------------------------------
//                         Description
//
//     CharNameMobile is a bar that shows the character name and image
//
//     (c) DXA Development 2024
// ----------------------------------------------------------------------------------

const CharNameMobile = (props) => {

  // ------------ parameters ---------------

  // ------ inherited state variables ------
  const [userData, setUserData] = useContext(UserDataContext);

  var currentChar = props.state[0];
  var setChar = props.state[1];

  const data = props.data;
  const setData = props.setdata;
  var spellUpdaters = props.spellUpdaters;

  // charID is used to ID the main div, which will be id="{charID}-div"
  const thisCharID = props.charID || '0'

  // -------- local state variables --------
  // const [ currentChar, setChar ] = useState( [ props.state[0], props.state[1] ] );
  var [ charImage, setCharImage ] = useState( process.env.PUBLIC_URL + '/img/char/' + currentChar + '.jpg' );
  const [ editing, setEditing ] = useState(false);
  const [ currentSpell, setCurrentSpell] = useState('Charmobile Knock');
  const [ currentSpellData, setCurrentSpellData ] = useState({});



  // -------- USE EFFECT  --------

  useEffect( () => {

    async function DoSomething () {  

        setCharImage(process.env.PUBLIC_URL + '/img/char/' + currentChar + '.jpg');
        
    }

    DoSomething();

}, [ currentChar ]) ; // END useEffect



  // -------- RETURN JSX --------

  return (

    <>
      <div id="charname-mobile">
        
        <div  id = { 'charImage-mobile' } >

          <div id="charnameName-mobile">{currentChar}</div>

          <img 
            src={ charImage }
            className = { '' }
            alt=""
            id={ "charPhoto-mobile" }
            style= {{
              zIndex: '1050'
            }}
          />
        </div>

      </div>
    </>
  )
}

export default CharNameMobile;