import { React, useState, useEffect } from 'react';

import NavBarMobile from '../components/NavBarMobile'
import CharNameMobile from '../components/CharNameMobile.js'
import RulesWindow from '../components/RulesWindow'
import MobileSpellDetail from '../components/MobileSpellDetail'
import Help from '../components/Help'
import MobileDataPage from './MobileDataPage.js'
import AllSpells from '../components/AllSpells'
import sample_data_flat from '../components/Charsheet0/helpers/sample_data_flat.js'

import Image01 from '../pdf/CombatActions.jpg'

import './MobileHome.css'



function MobileHome(props) {


  // ------------- VARIABLES -------------


  const [rulesWindowSrc, setRulesWindowSrc ] = useState( { title: 'Combat Actions', source: Image01 } )

  // ------------- RETURN JSX -------------

  return (
  <>
    <NavBarMobile {...props} setRule={ setRulesWindowSrc } className="no-print" />
    
    <RulesWindow {...props} src={ rulesWindowSrc } />

    <CharNameMobile {...props} charID='charMobile'/>
      
    <MobileDataPage {...props} charID='charMobile'/>

    <AllSpells {...props} UItype='mobile'/>

    <MobileSpellDetail {...props} />

    <Help />
  </>
  )
}

export default MobileHome