import React, { useState, useEffect } from 'react';

import EditableLink from './EditableLink.js'

// ----------------------------------------------------------------------------------
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function Index(props) {

    // ---------- return html (jsx) ----------

    return (
        <EditableLink {...props} />
    )

    };  // end of function

    export default Index