import { useState, useEffect } from 'react'
import CharsheetMobile from '../components/CharsheetMobile/CharsheetMobile.js'
import sample_data_flat from '../components/Charsheet0/helpers/sample_data_flat.js'
import LOAD_CHAR from '../components/Charsheet0/helpers/load_char2.js'
import flatten from '../components/Charsheet0/helpers/flatten.js'
import RollDice from '../components/RollDice'

// This page exists between MobileHome and CharsheetMobile
//   it's purpose is to own the character data

function MobileDataPage(props) {

  // character data
  const [ char0data, setChar0Data ] = useState( sample_data_flat );

  const currentChar = props.state[0];
  const setChar = props.state[1];

  // spell check data
  let spellCheckArray = [ [0], [1], [2], [3], [4], [5], [6], [7], [8], [9], [10] ]
  let spellChecks11 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks11);
  let spellChecks12 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks12);
  let spellChecks13 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks13);
  let spellChecks14 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks14);
  let spellChecks15 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks15);
  let spellChecks16 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks16);
  let spellChecks17 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks17);
  let spellChecks18 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks18);
  let spellChecks19 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks19);
  let spellChecks20 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks20);
  let spellChecks21 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks21);
  let spellChecks22 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks22);
  let spellChecks23 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks23);
  let spellChecks24 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks24);
  let spellChecks25 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks25);
  let spellChecks26 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks26);
  let spellChecks27 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks27);
  let spellChecks28 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks28);
  let spellChecks29 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks29);
  let spellChecks30 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks30);
  let spellChecks31 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks31);
  let spellChecks32 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks32);
  let spellChecks33 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks33);
  let spellChecks34 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks34);
  let spellChecks35 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks35);
  let spellChecks36 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks36);
  let spellChecks37 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks37);
  let spellChecks38 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks38);
  let spellChecks39 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks39);
  let spellChecks40 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks40);
  let spellChecks41 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks41);
  let spellChecks42 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks42);
  let spellChecks43 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks43);
  let spellChecks44 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks44);
  let spellChecks45 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks45);
  let spellChecks46 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks46);
  let spellChecks47 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks47);
  let spellChecks48 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks48);
  let spellChecks49 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks49);
  let spellChecks50 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks50);
  let spellChecks51 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks51);
  let spellChecks52 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks52);
  let spellChecks53 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks53);
  let spellChecks54 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks54);
  let spellChecks55 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks55);
  let spellChecks56 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks56);
  let spellChecks57 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks57);
  let spellChecks58 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks58);
  let spellChecks59 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks59);
  let spellChecks60 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks60);
  let spellChecks61 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks61);
  let spellChecks62 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks62);
  let spellChecks63 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks63);
  let spellChecks64 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks64);
  let spellChecks65 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks65);
  let spellChecks66 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks66);
  let spellChecks67 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks67);
  let spellChecks68 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks68);
  let spellChecks69 = useState(['69   ','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks69);
  let spellChecks70 = useState(['Empty','Empty','Empty','Empty','Empty','Empty']); spellCheckArray.push(spellChecks70);

  

  useEffect( () => {

    async function DoSomething () {

      var charData = await LOAD_CHAR( currentChar, setChar, false );

      const flatCharData = flatten(charData)
      setChar0Data( flatCharData );

      // set spell check data
      if (spellCheckArray) {
        for (let i=11; i<=55; i++) {
          const spellCheckVarName = "mySpk" + String(i)

          // the second item in the Array is the update function
          let updateSpellCheck = spellCheckArray[i][1]
          
          const spellChecksThis = flatCharData[spellCheckVarName]
          
          // set the data if it exists
          if (typeof spellChecksThis !== 'undefined') {
            updateSpellCheck( spellChecksThis )

          } else {
          // set to Empty if no data
            console.log('spellCheck data missing')
            updateSpellCheck(['Empty','Empty','Empty','Empty','Empty','Empty'])
          }
            
        }
      }
    }

    DoSomething();

}, [ currentChar, setChar ]) ; //useEffect

  return (
    <> 
      <CharsheetMobile state={ props.state } charID='charMobile' data={ char0data } setdata={ setChar0Data } spellCheckArray={spellCheckArray}/>
      <RollDice IDadd="mobile"/>
    </>
  )
}

export default MobileDataPage; 
