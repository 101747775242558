import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

import './SongPlayer.css'

const allSongs = [
  require('../mp3/01 Immigrant Song.mp3'),
  require('../mp3/02 The Wizard.mp3'),
  require('../mp3/03 Dungeon Master.mp3'),
  require('../mp3/04 Hall Of The Mountain King.mp3'),
  require('../mp3/05 Critical Hit.mp3'),
  require('../mp3/06 The Battle of Evermore.mp3'),
  require('../mp3/07 Lords Of The Ring.mp3'),
  require('../mp3/08 Man On The Silver Mountain.mp3'),
  require('../mp3/09 In The Garage.mp3'),
]

// Manually enter song filenames
const songNames = ['01 Immigrant Song.mp3'];

const SongPlayer = () => {
  const [currentSong, setCurrentSong] = useState(0);
  const [currentSongURL, setCurrentSongURL] = useState(allSongs[0]);

  const playNextSong = () => {

    setCurrentSong( (currentSong + 1) % allSongs.length );
  };

      // ------------- useEffect ---------------

      useEffect( () => {

        async function DoSomething () {
          setCurrentSongURL(allSongs[currentSong])
          
        }

        DoSomething();

    }, [currentSong]); //useEffect

  return (
    <div id="songPlayerDiv">

      {/* <audio controls playing onEnded={()=> playNextSong} style={ {justifyContent: 'left'}}>
        <source src={allSongs[currentSong]} type="audio/mp3" />
  </audio> */}
       
      <button id="songPlayerNext" onClick={() => setCurrentSong( (currentSong + 1) % allSongs.length )}>
        {'Next:'}
        <div id="nextSongTitle">{allSongs[( (currentSong + 1) % allSongs.length )].split('.')[0].split('/')[3].substring(3).split(' ').slice(0, 2).map(word => word.substring(0, 6)).join(' ') }</div>
      </button>
      <ReactPlayer
        url={currentSongURL}
        playing
        controls
        height="96%"
        onEnded={ playNextSong }
        id="reactPlayer"
        width="222px"
  /> 
      <div id='downloadPatcher'></div>
      </div>
      );
    };
    
    export default SongPlayer;