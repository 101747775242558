import React, { useState, useEffect, useContext } from 'react';

import Dropdown from '../common/Dropdown'

import LOAD_CHAR from '../Charsheet0/helpers/load_char2.js'
import SAVE_CHAR from '../Charsheet0/helpers/save_char.js'
import flatten from '../Charsheet0/helpers/flatten.js'
import unflatten from '../Charsheet0/helpers/unflatten.js'

import { UserDataContext } from "../../App.js";

// ----------------------------------------------------------------------------------
//                         Description
//
//     What does NavBarButton do?
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function CharactersButton(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------
    let [charData, setCharData] = props.state;
    const [userData, setUserData] = useContext(UserDataContext);

    // -------- local state variables --------

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {    

        }

        DoSomething();

    },); //useEffect

    // ---------- return html (jsx) ----------

    const characters = ['Yarg Smashskull', 'Lanora Snugglebottom', '---', 
                        'Charles', 'Paul', '---', 
                        'Edward the Enforcer', 'Martin', '---',
                        'Tonk', 'Davbin', '---',
                        'Aeoni', 'Mavis', '---',
                        'Eli', 'Trunk', 'Trea', 'Zum', 'Fonk', 'Gerthmig', '---', 
                        'Sylonnus', 'Grok-Nar', '---',
                        'blank', 'Load', 'Save', 'Save Local Backup'];

    const handleMenu = async function (selection) {

      switch( characters[selection] ) {

        case('---'):
          break;

        case('Load'):
          handleLoad();
          break;
        
        case('Save'):
        let cs = unflatten(charData);
        console.log (cs);
        if ( userData.loggedIn ) { 
          SAVE_CHAR(cs, cs.name, cs.campaign )
        } else {
            alert('Log in required\n\nSettings > Log In')
        }
        break;

        default:
          handleLoad(characters[selection]);
          break;
      }
    }

    const handleLoad = async ( charname = '' ) => {

      let new_data;
      
      if ( charname === '' ) {
          new_data = await LOAD_CHAR('', setCharData, true);
          setCharData(flatten(new_data))
      
        } else {
          new_data = await LOAD_CHAR(charname, setCharData, false);
          console.log('NEW DATA flattened',flatten(new_data));
          setCharData(flatten(new_data))
      }
    }

    return (
        <>
        <Dropdown
            trigger = {<button id="nav-menu-characters" className="cl-load-but no-print">▼</button>}
            menu = { characters.map ( ( character, idx) => 
                { return <button onClick={()=>handleMenu(idx)}>{character}</button>}
            )}
            divClass='character-dropdown-sm'
            menuClass='characters-menu-sm character-menu' 
        />
        </>
    )

    };  // end of function

    export default CharactersButton