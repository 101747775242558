import React from 'react';

import Spell from './Spell.js'

// ----------------------------------------------------------------------------------
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function Index(props) {

    // ---------- return html (jsx) ----------

    return (
        <Spell {...props} />
    )

    };  // end of function

    export default Index