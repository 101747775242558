function unflatten ( flatCharData, dataSchema = 0.3 ) {

    // takes flatCharData and repacks into charData according to the specified dataSchema

    let N = {}; // charData dictionary

    let F = flatCharData;

    if ( dataSchema >= 0.4 ) {
        // add code for spellCheckData
    }

    if ( dataSchema >= 0.3 ) {

            N = { abilities: {}, armor:{}, chars:{}, items:{}, magic:{}, potions:{}, followers: {}, saves:{}, spells:{}, stats:{}, weapons:{} }
            
            N.name = F.name;
            N.campaign = F.campaign;
            // N.version = dataSchema.toString();

            N.abilities.myAbi = F.myAbi;
            N.abilities.myRabi = F.myRabi;

            N.armor.myBaseAC = F.myBaseAC;
            N.armor.myArm1 = F.myArm1;
            N.armor.myArm2 = F.myArm2;
            N.armor.myArm3 = F.myArm3;
            N.armor.myArm4 = F.myArm4;
            N.armor.myArm5 = F.myArm5;
            N.armor.myAb1 = F.myAb1;
            N.armor.myAb2 = F.myAb2;
            N.armor.myAb3 = F.myAb3;
            N.armor.myAb4 = F.myAb4;
            N.armor.myAb5 = F.myAb5;

            N.chars.myAC = F.myAC;
            N.chars.myAlign = F.myAlign;
            N.chars.myClass = F.myClass;
            N.chars.myDeity = F.myDeity;
            N.chars.myGold = F.myGold;
            N.chars.myHei = F.myHei;
            N.chars.myWei = F.myWei;
            N.chars.myHPbase = F.myHPbase;
            N.chars.myHPcurr = F.myHPcurr;
            N.chars.myHPnote = F.myHPnote;
            N.chars.myLevel = F.myLevel;
            N.chars.myMov = F.myMov;
            N.chars.myNatt = F.myNatt;
            N.chars.myRace = F.myRace;
            N.chars.myRear = F.myRear;
            N.chars.mySide = F.mySide;
            N.chars.myToHit = F.myToHit;
            N.chars.myXP = F.myXP;
            N.chars.myXPbonus = F.myXPbonus;
            N.chars.myXPnext = F.myXPnext;

            N.items.myOth1 = F.myOth1;   
            N.items.myOth2 = F.myOth2;
            N.items.myOth3 = F.myOth3;
            N.items.myOth4 = F.myOth4;
            N.items.myOth5 = F.myOth5;

            N.magic.myMag1 = F.myMag1;   
            N.magic.myMag2 = F.myMag2;
            N.magic.myMag3 = F.myMag3;
            N.magic.myMag4 = F.myMag4;
            N.magic.myMag5 = F.myMag5;

            N.potions.myPot1 = F.myPot1;   N.potions.myQpo1 = F.myQpo1;
            N.potions.myPot2 = F.myPot2;   N.potions.myQpo2 = F.myQpo2;
            N.potions.myPot3 = F.myPot3;   N.potions.myQpo3 = F.myQpo3;
            N.potions.myPot4 = F.myPot4;   N.potions.myQpo4 = F.myQpo4;
            N.potions.myPot5 = F.myPot5;   N.potions.myQpo5 = F.myQpo5;
            N.potions.myPot6 = F.myPot6;   N.potions.myQpo6 = F.myQpo6;
            
            N.followers.myFol1 = F.myFol1; 
            N.followers.myFol2 = F.myFol2;

            N.saves.mySav1 = F.mySav1;   
            N.saves.mySav2 = F.mySav2;
            N.saves.mySav3 = F.mySav3;
            N.saves.mySav4 = F.mySav4;
            N.saves.mySav5 = F.mySav5;
            N.saves.mySbon1 = F.mySbon1;   
            N.saves.mySbon2 = F.mySbon2;
            N.saves.mySbon3 = F.mySbon3;
            N.saves.mySbon4 = F.mySbon4;
            N.saves.mySbon5 = F.mySbon5;
            N.saves.mySbon6 = F.mySbon6;

            N.spells.mySp11 = F.mySp11;
            N.spells.mySp12 = F.mySp12;
            N.spells.mySp13 = F.mySp13;
            N.spells.mySp14 = F.mySp14;
            N.spells.mySp15 = F.mySp15;
            N.spells.mySp16 = F.mySp16;
            N.spells.mySp17 = F.mySp17;
            N.spells.mySp18 = F.mySp18;
            N.spells.mySp19 = F.mySp19;
            N.spells.mySp20 = F.mySp20;

            N.spells.mySp21 = F.mySp21;
            N.spells.mySp22 = F.mySp22;
            N.spells.mySp23 = F.mySp23;
            N.spells.mySp24 = F.mySp24;
            N.spells.mySp25 = F.mySp25;
            N.spells.mySp26 = F.mySp26;
            N.spells.mySp27 = F.mySp27;
            N.spells.mySp28 = F.mySp28;
            N.spells.mySp29 = F.mySp29;
            N.spells.mySp30 = F.mySp30;

            N.spells.mySp31 = F.mySp31;
            N.spells.mySp32 = F.mySp32;
            N.spells.mySp33 = F.mySp33;
            N.spells.mySp34 = F.mySp34;
            N.spells.mySp35 = F.mySp35;
            N.spells.mySp36 = F.mySp36;
            N.spells.mySp37 = F.mySp37;
            N.spells.mySp38 = F.mySp38;
            N.spells.mySp39 = F.mySp39;
            N.spells.mySp40 = F.mySp40;

            N.spells.mySp41 = F.mySp41;
            N.spells.mySp42 = F.mySp42;
            N.spells.mySp43 = F.mySp43;
            N.spells.mySp44 = F.mySp44;
            N.spells.mySp45 = F.mySp45;
            N.spells.mySp46 = F.mySp46;
            N.spells.mySp47 = F.mySp47;
            N.spells.mySp48 = F.mySp48;
            N.spells.mySp49 = F.mySp49;
            N.spells.mySp50 = F.mySp50;

            N.spells.mySp51 = F.mySp51;
            N.spells.mySp52 = F.mySp52;
            N.spells.mySp53 = F.mySp53;
            N.spells.mySp54 = F.mySp54;
            N.spells.mySp55 = F.mySp55;  

            N.spells.myTsp1 = F.myTsp1;   N.spells.myNsp1 = F.myNsp1;
            N.spells.myTsp2 = F.myTsp2;   N.spells.myNsp2 = F.myNsp2;
            N.spells.myTsp3 = F.myTsp3;   N.spells.myNsp3 = F.myNsp3;
            N.spells.myTsp4 = F.myTsp4;   N.spells.myNsp4 = F.myNsp4;
            N.spells.myTsp5 = F.myTsp5;   N.spells.myNsp5 = F.myNsp5;

            N.stats.myStr = F.myStr
            N.stats.myInt = F.myInt
            N.stats.myWis = F.myWis
            N.stats.myDex = F.myDex
            N.stats.myCon = F.myCon
            N.stats.myCha = F.myCha

            N.stats.myStrBon = F.myStrBon
            N.stats.myIntBon = F.myIntBon
            N.stats.myWisBon = F.myWisBon
            N.stats.myDexBon = F.myDexBon
            N.stats.myConBon = F.myConBon
            N.stats.myChaBon = F.myChaBon

            N.weapons.myWeap1 = F.myWeap1
            N.weapons.myWeap2 = F.myWeap2
            N.weapons.myWeap3 = F.myWeap3
            N.weapons.myWeap4 = F.myWeap4
            N.weapons.myWeap5 = F.myWeap5

            N.weapons.myWth1 = F.myWth1
            N.weapons.myWth2 = F.myWth2
            N.weapons.myWth3 = F.myWth3
            N.weapons.myWth4 = F.myWth4
            N.weapons.myWth5 = F.myWth5

            N.weapons.myWdb1 = F.myWdb1
            N.weapons.myWdb2 = F.myWdb2
            N.weapons.myWdb3 = F.myWdb3
            N.weapons.myWdb4 = F.myWdb4
            N.weapons.myWdb5 = F.myWdb5

            N.weapons.myWdmg1 = F.myWdmg1
            N.weapons.myWdmg2 = F.myWdmg2
            N.weapons.myWdmg3 = F.myWdmg3
            N.weapons.myWdmg4 = F.myWdmg4
            N.weapons.myWdmg5 = F.myWdmg5
        

    }

    return N


}

export default unflatten