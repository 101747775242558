import React, { useState, useEffect } from 'react';

import './EditableLink.css'

import sanitizeInput from '../../../helpers/sanitizeInput';

// for testing only. remove later
import load_spell from '../../Spell/helpers/load_spell.js'

// ----------------------------------------------------------------------------------
//                         Description
//
//     EditableLink is a text input field that can be locked to become a hyperlink instead
//
//     <EditableLink initial="Fireball" link="spells(Fireball)" id="mysp31" />
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function EditableLink(props) {

    // ------------ parameters ---------------

    const MAXIMUM_TEXT_LENGTH = 44;

    // ------ inherited state variables ------

    if (props.data) {
        var data = props.data;
        var onDataChange = props.onDataChange;
    }

    // console.log('EDITABLE LINK: ', data);


    // -------- local state variables --------
    const [ linkValue, setLinkValue ] = useState(props.initial);

    // console.log('EL initial value ', linkValue )

    // ------------- useEffect ---------------

    useEffect( () => {

        async function updateData () {
            // when the initial value changes, update the link value
            setLinkValue(props.initial)
        }

        updateData();

    }, [props.initial]); //useEffect

    const updateValue = (e) => {
        // let newValue = $(this).val();
        setLinkValue(e.target.value);
        
        const newData = { ...data };
        const id = e.target.id;
        newData[id] = sanitizeInput(e.target.value);

        console.log('newData: ', e.target.id )
        onDataChange(newData);
    }

    // ---------- handler functions ----------
    
    const handleClick = (e) => {
        console.log('---------  EditableLink.handleClick ------------')
        if (e.target.innerHTML !== "") {
            load_spell(e.target.innerHTML, props.spellUpdaters, e.target );
        }
    }

    // ---------- return html (jsx) ----------

    return (
        <>
         <div className='editable-div'>
            <button id={props.id+'-but'} className='stat-val-sm editable-link' onClick={ handleClick }>{ linkValue }</button></div>
         <div className='editable-div'>
            <input id={props.id} className="editable-input stat-val-sm" type="text" 
            defaultValue={linkValue} maxLength={ MAXIMUM_TEXT_LENGTH} 
            onBlur={e => updateValue(e) }
            onChange={(event) => {
                
            }}/>
        </div>
        </>
    )

    };  // end of function

    export default EditableLink